import { KeyValueItem, State } from "./types";
import { produce } from "immer";
import Item from "./Item";
import validateItem from "./validateItem";

export default function ItemList({
  paginatedItems,
  state,
  onChange,
}: {
  paginatedItems: KeyValueItem[][];
  state: State;
  onChange: (updatedState: State) => void;
}) {
  function updateRow(id: string, fieldName: string, fieldValue: string) {
    const updatedState = produce(state, draft => {
      const index = draft.items.findIndex(item => item.id === id);
      // update item field
      draft.items[index][fieldName] = fieldValue;

      const error = validateItem(state, draft.items[index]);

      if (draft.errors[id]) delete draft.errors[id];
      if (error) draft.errors[id] = error;
    });

    onChange(updatedState);
  }

  function removeRow(id: string) {
    const updatedState = produce(state, draft => {
      const index = draft.items.findIndex(item => item.id === id);
      // remove from items
      draft.items.splice(index, 1);

      // remove from errors
      delete draft.errors[id];
    });

    onChange(updatedState);
    // onChange(itemsToRows(updatedState.items), isValid(updatedState.errors));
  }

  return (
    <>
      {paginatedItems[state.page - 1].map(item => {
        const errors = state.errors[item.id] || {};

        return (
          <Item
            key={item.id}
            item={item}
            onChange={(fieldName, fieldValue) =>
              updateRow(item.id, fieldName, fieldValue)
            }
            onRemove={() => removeRow(item.id)}
            error={errors}
            canDelete={state.items.length !== 1}
          />
        );
      })}
    </>
  );
}
