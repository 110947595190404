import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { Button } from "components/Forms/StyledComponents";
import { grey } from "@material-ui/core/colors";

import { TracingReportFields, TracingSearchRequest } from "@dashboard-v3/api";

interface FieldsSelectProps {
  isOpen: boolean;
  search: TracingSearchRequest;
  onClose: () => void;
  onConfirm: (criteria: TracingSearchRequest) => void;
}

type ReportFields = { [key in TracingReportFields[number]]?: boolean };

export default function ReportConfigModal(props: FieldsSelectProps) {
  const { t } = useTranslation("tracing");
  const classes = useStyles();
  const { isOpen, onClose, onConfirm, search } = props;
  const [fields, setFields] = useState<ReportFields>(initState(search));
  const fieldNames = Object.keys(fields) as TracingReportFields;
  const allSelected = fieldNames.every(key => fields[key]);
  const selectedFields = fieldNames.filter(f => fields[f]);

  const onChange = (field: string) => () => {
    setFields(prev => ({ ...prev, [field]: !prev[field] }));
  };

  const onSelectAll = () => {
    setFields(prev => {
      const update = { ...prev };
      for (const key in prev) update[key] = !allSelected;
      return update;
    });
  };

  const onSubmit = () => {
    onConfirm({
      ...search,
      reportFields: selectedFields as TracingReportFields,
    });

    onClose();
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle className={classes.title} disableTypography>
        <Typography variant="h6">{t("reportsConfigModal.title")}</Typography>
        <Typography variant="caption" color="textSecondary">
          {t("reportsConfigModal.desc")}
        </Typography>
        <IconButton className={classes.closeBtn} size="small" onClick={onClose}>
          <CloseIcon fontSize="small" />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <div className={classes.fieldsContainer}>
          <Chip
            className={classes.field}
            label="time"
            color="primary"
            disabled
          />
          {fieldNames.map(name => (
            <Chip
              className={classes.field}
              label={name}
              color={fields[name] ? "primary" : "default"}
              onClick={onChange(name)}
              style={{
                ...(!fields[name] && { background: "white" }),
              }}
            />
          ))}
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          size="small"
          variant="text"
          wording={t(
            allSelected
              ? t("reportsConfigModal.deselect")
              : t("reportsConfigModal.selectAll")
          )}
          style={{ marginRight: "auto", marginLeft: "12px" }}
          onClick={onSelectAll}
        />
        <Button
          size="small"
          variant="text"
          wording={t("common:cancel")}
          onClick={() => onClose()}
        />
        <Button
          size="small"
          wording={t("common:create")}
          onClick={onSubmit}
          disabled={!selectedFields.length}
        />
      </DialogActions>
    </Dialog>
  );
}

const initState = (search: TracingSearchRequest) => {
  const { processStage } = search;
  const fields: ReportFields = {
    sender: true,
    cc: true,
    date: true,
    from: true,
    hasError: true,
    msgUID: true,
    subject: true,
    to: true,
  };

  if (!processStage) {
    fields.recipients = true;
    fields.recipient = true;
    fields.preprocess = true;
  }

  if (processStage === "preProcess") {
    fields.recipients = true;
  }

  if (processStage === "process") {
    fields.recipient = true;
  }

  return (): ReportFields => fields;
};

const useStyles = makeStyles({
  title: { padding: "16px 24px 0 24px", maxWidth: "98%" },
  fieldsContainer: {
    background: grey[200],
    borderRadius: 8,
    padding: 10,
    margin: "20px 0",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    gap: "10px",
  },
  field: {
    height: 28,
    minWidth: 55,
    cursor: "pointer",
    lineHeight: "1.5",
  },
  closeBtn: { position: "absolute", top: "0.5rem", right: "0.5rem" },
});
