import { useTranslation } from "react-i18next";
import { Typography } from "@material-ui/core";
import { EnableButton, DisabledLabel, StorageAccountsLink } from "../Styled";
import { isIndividual, isOrganization } from "../utils";

import { Item } from "../types";

interface Props {
  item: Item;
  onLabelClick: (item: Item) => void;
}

export default function AccountOptionLabel({ item, onLabelClick }: Props) {
  const { t } = useTranslation("rules");
  const { option, storageAccount } = item;
  const { authentication, provider } = storageAccount;
  const { enabled, subTitle } = option;
  const IndividualLabel = () => (
    <Typography variant="caption">
      {t(subTitle)}
      {!enabled &&
        t("cloudStorage.accounts.org.notSynced", {
          pre: " - ",
        })}
    </Typography>
  );

  if (provider === "DROPBOX") {
    return (
      <Typography data-testid="rules__synced-label" variant="caption">
        {t("cloudStorage.header.subtitle.DROPBOX")}
      </Typography>
    );
  }

  if (provider === "MXHERO_STORAGE") {
    return (
      <Typography data-testid="rules__synced-label" variant="caption">
        {enabled ? t("common:enabled") : t("common:disabled")}
      </Typography>
    );
  }

  if (isIndividual(authentication) && enabled) {
    return <IndividualLabel />;
  }

  if (isOrganization(authentication) && enabled) {
    return (
      <Typography data-testid="rules__synced-label" variant="caption">
        {t("synced")}
      </Typography>
    );
  }

  return (
    <>
      {isOrganization(authentication) && (
        <DisabledLabel
          data-testid="storage__not-synced-label"
          variant="caption"
        >
          {t("cloudStorage.accounts.org.notSynced")}
        </DisabledLabel>
      )}
      {isIndividual(authentication) && <IndividualLabel />}
      <EnableButton
        size="small"
        color="primary"
        disableElevation
        data-testid="storage__not-synced-btn"
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
          onLabelClick(item);
        }}
        variant="contained"
      >
        {t("common:enable")}
      </EnableButton>
      {isIndividual(authentication) && (
        <StorageAccountsLink
          onClick={() => window.open("/integrations", "_blank")}
        >
          {t("cloudStorage.accounts.goToStorage")}
        </StorageAccountsLink>
      )}
    </>
  );
}
