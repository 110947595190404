import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { CircularProgress, DialogTitle } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import {
  handleStorageAccount,
  getErrorMsg,
} from "pages/Integrations/Accounts/helpers";

import {
  StorageAccount,
  StorageProvider,
  StorageProviderWithAuth,
} from "@dashboard-v3/api";
import storageProviders from "utils/storageProviders";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onAccountAdd: (
    change: Pick<Storage, "authentication" | "provider" | "userId">
  ) => void;
}

export default function AddAccountModal(props: Props) {
  const { t } = useTranslation("storage");
  const { enqueueSnackbar } = useSnackbar();
  const [selected, setSelected] = useState<StorageProvider>(null);
  const { isOpen, onClose, onAccountAdd } = props;
  const options = storageProviders.filter(p => p !== "EGNYTE_GOV");

  const onSuccess = (account: StorageAccount, provider: StorageProvider) => {
    const storageAccountUpdate = {
      authentication: "INDIVIDUAL",
      userId: account.user,
      provider,
    };
    onAccountAdd(storageAccountUpdate);
    onClose();
  };

  const onError = (error: unknown, isCancel: boolean) => {
    if (!isCancel) {
      enqueueSnackbar(getErrorMsg(error, t), {
        variant: "error",
        resumeHideDuration: 5000,
      });
      onClose();
    }
  };

  const handleItemClick = async (provider: StorageProviderWithAuth) => {
    if (selected && selected === provider) return;

    setSelected(provider);

    await handleStorageAccount(provider, { onSuccess, onError });

    setSelected(null);
  };

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="xs">
      <DialogTitle>{t("add")}</DialogTitle>
      <List>
        {options.map(provider => (
          <ListItem
            key={provider}
            button
            onClick={() => handleItemClick(provider)}
            disabled={selected && selected !== provider}
          >
            <ListItemText primary={t(provider)} />
            {selected === provider && <CircularProgress size={20} />}
          </ListItem>
        ))}
      </List>
    </Dialog>
  );
}
