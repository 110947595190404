import { ChangeEventHandler, useEffect, useState } from "react";
import produce from "immer";
import { useTranslation } from "react-i18next";
import { MenuItem, TextField } from "@material-ui/core";
import { OptionGroup } from "components/Styled";
import SwitchField from "components/Forms/SwitchField";
import SelectField from "components/Forms/SelectField";
import { isBlank } from "utils/string";
import { SizeSelectContainer } from "./Styled";
import {
  attachmentSizeOpts,
  getContentFilter,
  getContentFilterDefaults,
} from "./utils";
import DropDownFields from "./DropDownFields";
import ChipInputs from "./ChipInputs";
import { useRuleContext } from "../CreateOrEdit/context/ruleContext";

import { ContentFilter } from "@dashboard-v3/api";
import { FilterName } from "./types";

type EnabledFilters = FilterName[];

export default function ActionFilters() {
  const { t } = useTranslation("rules");
  const { state, dispatch } = useRuleContext();
  const filters = getContentFilter(state);
  const [enabledFields, setEnabledFields] = useState<EnabledFilters>([]);
  const attachmentSize = filters?.attachmentSize;
  const isZipAttachmentsRule = state.type === "ZIP_ATTACHMENTS";

  const updateFilter = (values: Partial<ContentFilter>) => {
    const update = { ...filters, ...values };
    dispatch({ type: "updateFilterAction", payload: update });
  };

  useEffect(() => {
    if (!filters) return updateFilter(getContentFilterDefaults());

    const enabled: EnabledFilters = Object.keys(filters).reduce((acc, key) => {
      if (filters[key] && filters[key].length) return [...acc, key];
      if (filters.attachmentSize) return [...acc, "attachmentSize"];
      return acc;
    }, []);

    setEnabledFields(enabled);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isEnabled = (name: FilterName) => {
    return enabledFields.includes(name);
  };

  const handleEnabledFields = e => {
    const { name } = e.target;
    setEnabledFields(prev => {
      if (prev.includes(name)) {
        const initialValues = getContentFilterDefaults();
        const reset = produce(filters, draft => {
          draft[name] = initialValues[name];
        });
        updateFilter(reset);
        return prev.filter(n => n !== name);
      }
      return [...prev, name];
    });
  };

  const handleAttachmentSize = event => {
    const { name, value } = event.target as {
      name: "size" | "format";
      value: string;
    };

    const attachmentSize = produce(filters, draft => {
      let parsedValue = name === "size" ? parseFloat(value) : value;
      draft.attachmentSize = { ...draft.attachmentSize, [name]: parsedValue };
    });
    updateFilter(attachmentSize);
  };

  const handleFilterSwitch: ChangeEventHandler<HTMLInputElement> = e => {
    const { name, checked } = e.target;
    const update = produce(filters, draft => {
      draft[name] = checked;
    });
    updateFilter(update);
  };

  const handleZipOption: ChangeEventHandler<HTMLInputElement> = e => {
    const { name, checked } = e.target;
    const initialValues = {
      ...getContentFilterDefaults(),
      excludeInlineImages: false,
      includeRfc822: false,
    };

    const update = produce(filters, draft => {
      draft[name] = checked;

      if (!checked) return;

      Object.keys(draft).forEach(key => {
        if (key === "includeZipWithPassword") return;
        draft[key] = initialValues[key];
        setEnabledFields(prev => prev.filter(n => n === key));
      });
    });

    updateFilter(update);
  };

  return (
    <>
      <OptionGroup>
        <SwitchField
          name="attachmentSize"
          label={t("filterAttachmentsBySize")}
          description={t("filterAttachmentsBySizeDescription")}
          onChange={handleEnabledFields}
          value={isEnabled("attachmentSize")}
          disabled={!!filters?.includeZipWithPassword}
        />
        {isEnabled("attachmentSize") && (
          <SizeSelectContainer>
            <TextField
              name="size"
              type="number"
              variant="outlined"
              fullWidth
              inputProps={{ min: 1 }}
              onChange={handleAttachmentSize}
              value={isNaN(attachmentSize?.size) ? "" : attachmentSize?.size}
              error={!isValidSize(attachmentSize?.size)}
              helperText={
                !isValidSize(attachmentSize?.size) &&
                t("actionFilters.attachmentSize.number.invalid")
              }
            />
            <SelectField
              name="format"
              label={t("actionFilters.attachmentSize.format.label")}
              options={attachmentSizeOpts}
              onChange={handleAttachmentSize}
              value={attachmentSize?.format || ""}
              renderOption={({ key, value, text }) => (
                <MenuItem key={key} value={value}>
                  {text}
                </MenuItem>
              )}
            />
          </SizeSelectContainer>
        )}
      </OptionGroup>
      <ChipInputs
        isEnabled={isEnabled}
        handleEnabledFields={handleEnabledFields}
        updateFilter={updateFilter}
      />
      <DropDownFields
        isEnabled={isEnabled}
        handleEnabledFields={handleEnabledFields}
        updateFilter={updateFilter}
      />
      <OptionGroup>
        <SwitchField
          name="excludeInlineImages"
          label={t("excludeInlineImages")}
          description={t("excludeInlineImagesDescription")}
          onChange={handleFilterSwitch}
          value={filters ? filters.excludeInlineImages : true}
          disabled={!!filters?.includeZipWithPassword}
        />
      </OptionGroup>
      {!isZipAttachmentsRule && (
        <OptionGroup>
          <SwitchField
            name="includeZipWithPassword"
            label={t("includeZipWithPasswordLabel")}
            description={t("includeZipWithPasswordDesc")}
            onChange={handleZipOption}
            value={filters ? filters.includeZipWithPassword : true}
          />
        </OptionGroup>
      )}
      <OptionGroup>
        <SwitchField
          name="includeRfc822"
          label={t("includeRfc822Label")}
          description={t("includeRfc822Desc")}
          onChange={handleFilterSwitch}
          value={filters ? filters.includeRfc822 : true}
          disabled={!!filters?.includeZipWithPassword}
        />
      </OptionGroup>
    </>
  );
}

function isValidSize(value?: number) {
  if (isBlank(value)) return true;

  if (isNaN(value) || !Number.isInteger(value)) {
    return false;
  }

  if (value <= 0) return false;

  return true;
}
