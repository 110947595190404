import styled from "styled-components";
import {
  AccordionDetails,
  AccordionSummary,
  Button as MuiButton,
  Divider,
  Grid,
  MenuItem,
  Paper,
  TextField,
  Typography,
  Link,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CloseIcon from "@material-ui/icons/Close";
import { Button } from "components/Forms/StyledComponents";
import { HeaderProps } from "./types";

const HeaderContainer = styled.header`
  border-bottom: 1px solid #d6dbe0;
  padding: 14px 24px;
`;

export const ContentWrapper = styled.div`
  padding: 24px;
`;

export const ConfirmButton = styled(Button)`
  margin-top: 24px;
`;

export const StyledMenuItem = styled(MenuItem)`
  display: block;
  opacity: 1 !important;
  cursor: ${({ disabled }) => disabled && "default"};
  pointer-events: ${({ disabled }) => disabled && "none"};
  p {
    opacity: ${({ disabled }) => disabled && "0.5"};
  }
  &:hover {
    background: ${({ disabled }) => disabled && "none"};
  }
`;

export const TextFieldContainer = styled(StyledMenuItem)`
  background-color: transparent;
  :hover {
    background-color: transparent;
  }
`;

export const DisabledLabel = styled(Typography)`
  pointer-events: none;
  cursor: pointer;
`;

export const EnableButton = styled(MuiButton)`
  pointer-events: visible;
  padding: 2px 8px;
  font-size: 0.7rem;
  min-width: 60px;
  margin-left: 10px;
`;

export const StorageAccountsLink = styled(Link)`
  margin-left: 10px;
  font-size: 12px;
  cursor: pointer;
  pointer-events: visible;
`;

export const StyledDetails = styled(AccordionDetails)`
  padding: 8px 24px 24px;
  flex-direction: column;
`;

export const StyledDivider = styled(Divider)`
  margin: 8px 0 8px 0;
`;

export const StorageContainer = ({ children }) => {
  return (
    <Grid container spacing={3}>
      <Grid item xs>
        <Paper>{children}</Paper>
      </Grid>
    </Grid>
  );
};

export const StepHeader = ({ title, subtitle, selected }: HeaderProps) => {
  const selectedStyle = { fontWeight: !selected ? "400" : "" };
  const color = selected ? "primary" : "inherit";
  return (
    <HeaderContainer>
      <Typography variant="h6">{title}</Typography>
      <Typography color={color} variant="h6" style={selectedStyle}>
        {subtitle}
      </Typography>
    </HeaderContainer>
  );
};

export const StyledSummary = ({ children }) => {
  return (
    <AccordionSummary
      data-testid="action__advanced-options-expand"
      expandIcon={<ExpandMoreIcon />}
      style={{ padding: "0 24px" }}
    >
      {children}
    </AccordionSummary>
  );
};

export const StyledInput = props => {
  return (
    <TextField
      autoFocus
      fullWidth
      style={{ marginTop: "6px", marginBottom: "6px" }}
      InputLabelProps={{ shrink: true }}
      {...props}
    />
  );
};

export const SyncDialogContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: flex-start;
  padding: 10px 20px 20px 20px;
  justify-content: center;
`;

export const SyncDialogTitleContainer = styled.div`
  width: 100%;
  position: relative;
`;

export const SyncDialogCloseButton = styled(CloseIcon)`
  font-size: 16px;
  position: absolute;
  right: -10px;
  top: 0;
  cursor: pointer;
  :hover {
    color: red;
  }
`;

export const StyledTextField = styled(TextField)({
  marginTop: "10px",
  marginBottom: "10px",
  "& .MuiFormHelperText-root.Mui-error": {
    position: "absolute",
    top: "98%",
    fontSize: "11px",
  },
});

export const WithHelperContainer = styled.div`
  display: flex;
  align-items: center;
  > label {
    margin-right: 5px;
  }
`;
