/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import produce from "immer";
import { Trans, useTranslation } from "react-i18next";
import {
  Grid,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
  Paper,
} from "@material-ui/core";
import { Label, OptionGroup } from "components/Styled";
import {
  ContentWrapper,
  ErrorMessage,
  ConfirmButton,
  SectionTitle,
} from "./Styled";
import ActionButton from "./ActionButton";
import AdvancedOptions from "./AdvancedOptions";
import { useRuleContext } from "../CreateOrEdit/context/ruleContext";
import {
  nameFormatDefaults,
  getContentErrors,
  containsEmailType,
  getActionState,
  isEmailType,
} from "./utils";

import { ActionControl, ActionState } from "./types";
import { Content, Storage } from "@dashboard-v3/api";
import { getStorage } from "../CloudStorage/utils";
import { RuleType } from "../types";

export default function RuleActions() {
  const { t } = useTranslation("rules");
  const { state, dispatch } = useRuleContext();
  const action = getActionState(state);
  const storage = getStorage(state.rule);
  const types = action?.content.map(c => c.type);
  const [controls, setControls] = useState<ActionControl>({
    isError: false,
    errorKey: "",
  });

  useEffect(() => {
    const errors = getContentErrors(types);
    const optText = !types?.includes("ATTACHMENTS")
      ? t("attachments")
      : t("emailBodyInPdf");
    setControls(prev =>
      produce(prev, draft => {
        if (errors.get("EMPTY")) {
          draft.isError = true;
          draft.errorKey = t("selectOneOption");
        } else if (errors.get(state.type)) {
          draft.isError = true;
          draft.errorKey = `${optText} ${t("optionMustBeSelected")}`;
        } else {
          draft.isError = false;
          draft.errorKey = "";
        }
      })
    );
  }, [types?.length]);

  function updateValues(partialChange: Partial<ActionState>) {
    const updated = { ...action, ...partialChange };

    dispatch({
      type: "updateStep",
      payload: { step: "action", changes: updated },
    });
  }

  const updateContentType = (val: Content["type"], state: ActionState) => {
    return produce(state, draft => {
      if (val === "EML" && types.includes("MSG")) {
        draft.content = state.content.filter(c => c.type !== "MSG");
      } else if (val === "EML" && types.includes("ORIGINAL")) {
        draft.content = state.content.filter(c => c.type !== "ORIGINAL");
      } else if (types.includes(val)) {
        draft.content = state.content.filter(c => c.type !== val);
      } else {
        draft.content.push({
          type: val,
          nameFormat: nameFormatDefaults(val),
        });
      }
    });
  };

  const handleContentType = (val: Content["type"]) => _ => {
    const updated = updateContentType(val, action);
    updateValues(updated);
  };

  const handleSaveFormat = event => {
    const { value }: { value: Content["type"] } = event.target;
    const changes = action.content.map(item => {
      if (isEmailType(item.type)) {
        return { ...item, type: value };
      }
      return item;
    });

    updateValues({ content: changes });
  };

  const handleConfirm = () => {
    dispatch({ type: "confirmStep", payload: "action" });
  };

  const isConfirmDisabled = () => {
    const { action } = state.steps;
    return !action.valid || action.done;
  };

  const isDisableOptions = () => {
    return (
      controls.isError ||
      state.type === "COPY_EMAILS" ||
      state.type === "DRAG_AND_DROP"
    );
  };

  if (state.type === "BLOCK_ATTACHMENTS") {
    return (
      <Paper>
        <SectionTitle type={state.type} />
        <ContentWrapper>
          <OptionGroup>
            <Label wording={t("allYourAttachmentsWillBeBlocked")} />
            <Trans i18nKey="youCanExcludeCertainAttachments" t={t}>
              <Typography variant="button">{t("addFilters")}</Typography>
            </Trans>
          </OptionGroup>
          <ConfirmButton
            fullWidth
            size="large"
            wording={t("confirm")}
            onClick={handleConfirm}
            disabled={isConfirmDisabled()}
          />
        </ContentWrapper>
      </Paper>
    );
  }

  return (
    <Paper>
      <SectionTitle type={state.type} />
      <ContentWrapper>
        <OptionGroup>
          <Label gutterBottom wording={t("selectWhatToSave")} />
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <ActionButton
                wording={t("email")}
                onClick={handleContentType("EML")}
                selected={containsEmailType(action.content)}
                testid="rules-copy_email-btn"
                disabled={isEmailDisabled(state.type, storage)}
              />
            </Grid>
            <Grid item xs={4}>
              <ActionButton
                wording={t("attachments")}
                onClick={handleContentType("ATTACHMENTS")}
                selected={types.includes("ATTACHMENTS")}
                testid="rules-copy_attachments-btn"
              />
            </Grid>
            <Grid item xs={4}>
              <ActionButton
                wording={t("emailBodyInPdf")}
                onClick={handleContentType("PDF")}
                selected={types.includes("PDF")}
                testid="rules-copy_pdf-btn"
                disabled={isPDFDisabled(state.type, storage)}
              />
            </Grid>
          </Grid>
          <ErrorMessage
            isError={controls.isError}
            wording={controls.errorKey}
          />
        </OptionGroup>
        {containsEmailType(action.content) && (
          <OptionGroup>
            <Label wording={t("emailSaveFormat")} />
            <RadioGroup
              value={types.find(t => isEmailType(t))}
              onChange={handleSaveFormat}
              row
            >
              <FormControlLabel
                label={t("ruleAction.saveFormat.eml")}
                value="EML"
                control={<Radio color="primary" />}
              />
              <FormControlLabel
                label={t("ruleAction.saveFormat.msg")}
                value="MSG"
                control={<Radio color="primary" />}
              />
              {state.rule.actionType === "DRAG_AND_DROP" && (
                <FormControlLabel
                  label={t("ruleAction.saveFormat.original")}
                  value="ORIGINAL"
                  control={<Radio color="primary" />}
                />
              )}
            </RadioGroup>
          </OptionGroup>
        )}
        <ConfirmButton
          fullWidth
          size="large"
          wording={t("confirm")}
          onClick={handleConfirm}
          disabled={isConfirmDisabled()}
        />
      </ContentWrapper>
      <AdvancedOptions
        ruleType={state.type}
        values={action}
        setValues={updateValues}
        disabled={isDisableOptions()}
      />
    </Paper>
  );
}

function isEmailDisabled(type: RuleType, storage: Storage) {
  if (type === "ZIP_ATTACHMENTS") return true;

  if (type === "PROTECT_EMAILS" || type === "REPLACE_ATTACHMENTS") {
    return storage?.provider === "MXHERO_STORAGE";
  }

  return false;
}

function isPDFDisabled(type: RuleType, storage: Storage) {
  if (type === "ZIP_ATTACHMENTS") return true;

  if (type === "REPLACE_ATTACHMENTS") {
    return storage?.provider === "MXHERO_STORAGE";
  }

  return false;
}
