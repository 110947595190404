import { useTranslation } from "react-i18next";
import { useRuleContext } from "./context/ruleContext";
import FloatingButton from "./FloatingButton";
import { useEffect, useState } from "react";
import { fetchStorageSettings } from "pages/Integrations/api";

interface Props {
  action: "ADD" | "REMOVE";
}

export default function ToggleStorageSteps(props: Props) {
  const { t } = useTranslation("rules");
  const { action } = props;
  const { state, dispatch } = useRuleContext();
  const [btnState, setBtnState] = useState({
    isAllowed: false,
    isLoading: true,
  });

  useEffect(() => {
    async function checkStorageSettings() {
      try {
        const settings = await fetchStorageSettings();
        setBtnState(prev => ({
          ...prev,
          isAllowed: settings?.mxheroStorage?.enabled,
        }));
      } catch (e) {
        setBtnState(prev => ({ ...prev, isAllowed: false }));
      } finally {
        setBtnState(prev => ({ ...prev, isLoading: false }));
      }
    }
    checkStorageSettings();
  }, []);

  const onClick = () => {
    dispatch({
      type: "toggleStorageSteps",
      payload: {
        action,
        currentStep: action === "ADD" ? "cloudStorage" : "target",
      },
    });
  };

  return (
    <FloatingButton
      label={t(`toggleStorageSettings.${action}`)}
      color={action === "ADD" ? "primary" : "error"}
      onClick={onClick}
      disabled={!state.steps.target.done || !btnState.isAllowed}
      loading={btnState.isLoading}
    />
  );
}
