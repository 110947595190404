import produce, { Draft } from "immer";
import i18n from "i18next";
import { nameFormatDefaults } from "pages/Rules/RuleActions/utils";
import {
  AccountManagement,
  canImpersonate,
  getStorage,
} from "pages/Rules/CloudStorage/utils";
import {
  formatTypeToField,
  getDefaultFolderStructure,
} from "pages/Rules/StorageFilepath/utils";
import { verifyIfCanSave } from "./verifyIfCanSave";
import validateStep from "./validateStep";
import {
  getArchivingAddress,
  getContentToSave,
  getEmailFlow,
  getMailbox,
} from "../helpers";

import { FromTo, Mailbox } from "@dashboard-v3/api";
import { State, Step } from "../types";

export function confirmStep(state: State, step: ConfirmStep["payload"]): State {
  return produce(state, draft => {
    validateStep(step, draft);
    const stepPath = draft.steps[step];
    if (stepPath.valid) {
      const asyncRequested = checkIfRequireAsyncValidation(step, draft);

      if (asyncRequested) return;

      setStepAsValid(step, draft);
    }
  });
}

export function setStepAsValid(
  step: ConfirmStep["payload"],
  draft: Draft<State>
) {
  if (draft.type === "ZIP_ATTACHMENTS") {
    return validateZipAttachments(step, draft);
  } else {
    const stepPath = draft.steps[step];
    const next = stepPath.next || step;
    stepPath.done = true;
    draft.currentStep = next;
    draft.steps[next].expanded = true;
  }

  verifyIfCanSave(draft);
}

function validateZipAttachments(
  step: ConfirmStep["payload"],
  draft: Draft<State>
) {
  const stepPath = draft.steps[step];
  const next = stepPath.next || step;

  stepPath.done = true;
  draft.steps[next].expanded = draft.currentStep !== "target";

  if (draft.currentStep === "cloudStorage") {
    draft.currentStep = next;
  }

  verifyIfCanSave(draft);
}

function checkIfRequireAsyncValidation(step: Step, draft: Draft<State>) {
  const stepsForValidation = ["target", "cloudStorage", "storageFilepath"];
  if (!stepsForValidation.includes(step)) return false;

  if (step === "target") {
    if (draft.rule.targetType === "ARCHIVING_ADDRESS") {
      const requiredValidation = archivingRequiredValidation(draft);
      const isValid = !requiredValidation;
      draft.checks.archivingAddress.isValidEmail = isValid;
      if (isValid) {
        draft.checks.emailFlow.error = null;
      }

      return requiredValidation;
    }

    if (draft.rule.targetType === "EMAIL_FLOW") {
      const requiredValidation = emailFlowRequiredValidation(draft);
      const isValid = !requiredValidation;
      draft.checks.emailFlow.isValid = isValid;
      if (isValid) {
        draft.checks.emailFlow.error = null;
      }
      return requiredValidation;
    }

    if (draft.rule.targetType === "MAIL_BOX") {
      const mailbox = getMailbox(draft.rule);
      if (mailbox.provider !== "IMAP") return false;
      const requiredValidation = mailboxRequiredValidation(draft);
      const isValid = !requiredValidation;
      draft.checks.mailboxImap.isValid = isValid;
      if (isValid) {
        draft.checks.mailboxImap.error = null;
      }
      return requiredValidation;
    }
  }

  if (step === "cloudStorage") {
    const storage = getStorage(draft.rule);
    const shouldValidate =
      canImpersonate(storage) &&
      draft.storageManagement !== AccountManagement.AUTO;

    if (shouldValidate) {
      draft.asyncStatus = "validate_storage_access";
      return true;
    }
  }

  if (step === "storageFilepath") {
    const fieldsToCheck = getFilenameFieldsToCheck(draft);

    if (fieldsToCheck.length) {
      draft.checks.storageFilename.fieldsToCheck = fieldsToCheck;
      draft.asyncStatus = "validate_filename_format";
      return true;
    }
  }

  return false;
}

function mailboxRequiredValidation(draft: Draft<State>) {
  const mailboxCheck = draft.checks.mailboxImap;

  if (sameAsMailboxAsInitial(draft)) return false;

  const isSame = sameMailboxConfigSinceLastCheck(draft);
  const lastCheckWasValid = mailboxCheck?.isValid;

  if (isSame && lastCheckWasValid) return false;
  if (isSame && !lastCheckWasValid) return true;

  if (!isSame) {
    // Request a validation
    draft.asyncStatus = "validate_mailbox_imap";
    return true;
  }
}

function sameAsMailboxAsInitial(draft: Draft<State>) {
  const initialValue = draft.checks.mailboxImap?.initialValue;
  if (!initialValue) return false;

  const current = getMailbox(draft.rule)?.imapServer;

  return isSameMailboxImap(current, initialValue);
}

function sameMailboxConfigSinceLastCheck(draft: Draft<State>) {
  const lastTested = draft.checks.mailboxImap?.tested;
  if (!lastTested) return false;

  const current = getMailbox(draft.rule)?.imapServer;

  return isSameMailboxImap(current, lastTested);
}

function isSameMailboxImap(
  one: Mailbox["imapServer"],
  other: Mailbox["imapServer"]
) {
  return (
    one.authenticationMechanism === other.authenticationMechanism &&
    one.host?.toLowerCase() === other.host?.toLowerCase() &&
    one.password === other.password &&
    one.port === other.port &&
    one.ssl === other.ssl &&
    one.username?.toLowerCase() === other.username?.toLowerCase()
  );
}

function emailFlowRequiredValidation(draft: Draft<State>) {
  const emailFlowCheck = draft.checks.emailFlow;

  // Is editing a rule and use the same flow as original (be validated previously)
  if (sameFlowAsInitial(draft)) return false;

  const isSame = sameFlowSinceLastCheck(draft);
  const lastCheckWasValid = emailFlowCheck?.isValid;

  if (isSame && lastCheckWasValid) return false;
  if (isSame && !lastCheckWasValid) return true;

  if (!isSame) {
    // Request a validation
    draft.asyncStatus = "validate_email_flow";
    return true;
  }
}

function sameFlowAsInitial(draft: Draft<State>) {
  const initialCheckedFlow = draft.checks.emailFlow?.initialValue;
  if (!initialCheckedFlow) return false;

  const emailFlow = getEmailFlow(draft.rule);
  const current = {
    bidirectional: emailFlow.bidirectional,
    fromTo: emailFlow.fromTo,
  };

  return isSameEmailFlow(current, initialCheckedFlow);
}

function sameFlowSinceLastCheck(draft: Draft<State>) {
  const alreadyCheckFlow = draft.checks.emailFlow?.tested;
  if (!alreadyCheckFlow) return false;

  const emailFlow = getEmailFlow(draft.rule);
  const current = {
    bidirectional: emailFlow.bidirectional,
    fromTo: emailFlow.fromTo,
  };

  return isSameEmailFlow(current, alreadyCheckFlow);
}

type EmailFlowUID = {
  bidirectional: boolean;
  fromTo: FromTo;
};

function isSameEmailFlow(one: EmailFlowUID, other: EmailFlowUID) {
  if (one.bidirectional !== other.bidirectional) return false;

  return ["fromDirection", "toDirection"].every(side => {
    const oneSide = one.fromTo[side];
    const otherSide = other.fromTo[side];
    return (
      oneSide.type === otherSide.type &&
      equalsIgnoreCase(oneSide.value, otherSide.value)
    );
  });
}

function archivingRequiredValidation(draft: Draft<State>) {
  const archivingCheck = draft.checks.archivingAddress;

  // Is editing a rule and use the same email as original (just already be validated previously)
  if (sameEmailAsInitial(draft)) return false;

  const isSameAccount = sameAccountSinceLastCheck(draft);
  const lastCheckWasValid = archivingCheck.isValidEmail;

  if (isSameAccount && lastCheckWasValid) return false;
  if (isSameAccount && !lastCheckWasValid) return true;

  if (!isSameAccount) {
    // Request a validation
    draft.asyncStatus = "validate_archiving_address";
    return true;
  }
}

function sameAccountSinceLastCheck(draft: Draft<State>) {
  const archivingCheck = draft.checks.archivingAddress;
  const archivingAddress = getArchivingAddress(draft.rule);

  const currentEmailAddress = archivingAddress.emailAddress;
  const addressAlreadyTested = archivingCheck?.testedEmail;

  return equalsIgnoreCase(currentEmailAddress, addressAlreadyTested);
}

function sameEmailAsInitial(draft: Draft<State>) {
  const archivingAddress = getArchivingAddress(draft.rule);
  const currentEmailAddress = archivingAddress.emailAddress;
  const initialAddress = draft.checks.archivingAddress?.initialAddress;

  return equalsIgnoreCase(currentEmailAddress, initialAddress);
}

function equalsIgnoreCase(one?: string, other?: string) {
  return one?.toLowerCase() === other?.toLowerCase();
}

const getFilenameFieldsToCheck = (draft: Draft<State>) => {
  const fieldsToCheck = [];
  const rule = draft.rule;
  const { content } = getContentToSave(rule);
  const { location } = getStorage(rule);
  const t = i18n.t.bind(i18n);
  const contentFieldsToCheck = content.filter(
    c => nameFormatDefaults(c.type) !== c.nameFormat
  );

  if (contentFieldsToCheck.length) {
    fieldsToCheck.push(
      ...contentFieldsToCheck.map(c => ({
        field: formatTypeToField(c.type),
        value: c.nameFormat,
      }))
    );
  }

  const isDefaultFolderStructure =
    location.folderStructure === getDefaultFolderStructure(rule, t);

  if (!isDefaultFolderStructure) {
    fieldsToCheck.push({
      field: "folderStructure",
      value: location.folderStructure,
    });
  }

  if (location.emailSubfolder) {
    fieldsToCheck.push({
      field: "emailSubfolder",
      value: location.emailSubfolder,
    });
  }

  if (location.attachmentsSubfolder) {
    fieldsToCheck.push({
      field: "attachmentsSubfolder",
      value: location.attachmentsSubfolder,
    });
  }

  if (location.bodySubfolder) {
    fieldsToCheck.push({
      field: "bodySubfolder",
      value: location.bodySubfolder,
    });
  }

  return fieldsToCheck;
};

export type ConfirmStep = {
  type: "confirmStep";
  payload: Step;
};
