import { useTranslation } from "react-i18next";
import {
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import styled from "styled-components";
import { ListHeaderTitle } from "components/List";
import Item from "./Item";
import { EventItem } from "@dashboard-v3/api";

const StyledTableBody = styled(TableBody)`
  tr {
    background-color: white;
    :hover {
      background-color: rgba(0, 0, 0, 0.04);
    }
  }
  :hover {
    background-color: transparent;
  }
`;

type Params = {
  activityList: EventItem[];
  loading: boolean;
};

export default function EventList({ activityList, loading }: Params) {
  const { t } = useTranslation("userActivity");

  if (loading)
    return (
      <Paper style={{ padding: "20px 16px" }} data-testid="resultList-loading">
        <LinearProgress />
      </Paper>
    );

  if (!activityList) return null;

  if (!activityList.length)
    return (
      <Paper
        style={{ padding: "20px 16px", textAlign: "center" }}
        data-testid="resultList-empty"
      >
        <Typography>{t("noResults")}</Typography>
      </Paper>
    );

  return (
    <TableContainer component={Paper} data-testid="resultList">
      <Table size="small">
        <TableHead>
          <TableRow style={{ height: "48px" }}>
            <TableCell>
              <ListHeaderTitle variant="button">{t("user")}</ListHeaderTitle>
            </TableCell>
            <TableCell>
              <ListHeaderTitle variant="button">{t("action")}</ListHeaderTitle>
            </TableCell>
            <TableCell>
              <ListHeaderTitle variant="button">
                {t("affected")}
              </ListHeaderTitle>
            </TableCell>
            <TableCell>
              <ListHeaderTitle variant="button">{t("date")}</ListHeaderTitle>
            </TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <StyledTableBody>
          {activityList.map(item => (
            <Item key={item.id} item={item} />
          ))}
        </StyledTableBody>
      </Table>
    </TableContainer>
  );
}
