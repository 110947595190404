import { useState } from "react";
import { useTranslation } from "react-i18next";
import MoreVertIcon from "@material-ui/icons/MoreVertOutlined";
import { Button, Menu, MenuItem, Typography } from "@material-ui/core";
import { EventItem, RuleEvent } from "@dashboard-v3/api";
import downloadFile from "utils/downloadFile";

interface DownloadMenuProps {
  item: EventItem;
}

export default function DownloadMenu({ item }: DownloadMenuProps) {
  const { t } = useTranslation("userActivity");
  const { data } = item.event as RuleEvent;
  const [anchor, setAnchor] = useState(null);
  const options = getMenuOptions(item);

  const onButtonClick: React.MouseEventHandler = e => {
    const { target } = e;
    e.stopPropagation();
    setAnchor(target);
  };

  const onMenuClose = e => {
    e.stopPropagation();
    setAnchor(null);
  };

  const onSelect = (value: string) => () => {
    if (value === "DOWNLOAD_DIFF" && "changes" in data) {
      const { changes, name } = data;
      downloadFile(changes, `${name}-DIFF`, "json");
    }

    if (value === "DOWNLOAD_REMOVED" && "deletedRule" in data) {
      const { deletedRule, name } = data;
      downloadFile(deletedRule, `${name}-DELETED`, "json");
    }

    if (value === "DOWNLOAD_UPDATED" && "currentRule" in data) {
      const { currentRule, name } = data;
      downloadFile(currentRule, `${name}-UPDATED`, "json");
    }

    setAnchor(null);
  };

  return (
    <>
      <Button
        size="small"
        style={{ backgroundColor: "transparent" }}
        disableFocusRipple
        disableRipple
        onClick={onButtonClick}
        disabled={!options.length}
      >
        <MoreVertIcon />
      </Button>
      <Menu
        anchorEl={anchor}
        open={Boolean(anchor)}
        onClose={onMenuClose}
        getContentAnchorEl={null}
        keepMounted
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {options.map(option => (
          <MenuItem key={option} value={option} onClick={onSelect(option)}>
            <Typography variant="caption">
              {t(`eventItem.menu.${option}`)}
            </Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

function getMenuOptions(item: EventItem) {
  const { event } = item;
  const options = [];

  if (event.type === "rule_deleted") {
    options.push("DOWNLOAD_REMOVED");
  }

  if (event.type === "rule_updated") {
    if ("changes" in event.data) options.push("DOWNLOAD_DIFF");
    if ("currentRule" in event.data) options.push("DOWNLOAD_UPDATED");
  }

  return options;
}
