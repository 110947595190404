/* eslint-disable no-undef */
/* eslint-disable no-console */
import Script from "@gumgum/react-script-tag";
import { useEffect } from "react";
import { PickerParams } from "../types";

// declare var google: any;

// API Documentation: https://developers.google.com/picker/docs
// The Browser API key obtained from the Google API Console.
// Replace with your own Browser API key, or your own key.
const developerKey = process.env.REACT_APP_GOOGLE_PICKER_KEY;

// The Client ID obtained from the Google API Console.
// Replace with your own Client ID.
const clientId = process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID;

// Replace with your own project number from console.developers.google.com.
// See "Project number" under "IAM & Admin" > "Settings"
const appId = process.env.REACT_APP_GOOGLE_PICKER_APP_ID;

// Scope to use to access user's Drive items.
const scope = "https://www.googleapis.com/auth/drive.file";

let tokenClient: google.accounts.oauth2.TokenClient = null;
let gisInited = false;
let pickerInited = false;
let accessToken: string = null;
let picker: google.picker.Picker = null;

export default function GooglePicker({ onChange, open }: PickerParams) {
  useEffect(() => {
    if (!open) return;

    if (pickerInited && gisInited) {
      if (!tokenClient) initTokenClient();

      if (accessToken === null) {
        tokenClient.requestAccessToken({ prompt: "consent" });
      } else {
        tokenClient.requestAccessToken({ prompt: "" });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pickerInited, gisInited, open]);

  function initTokenClient() {
    tokenClient = google.accounts.oauth2.initTokenClient({
      client_id: clientId,
      scope,
      callback: response => {
        if (response.error !== undefined) {
          throw response;
        }
        accessToken = response.access_token;
        showPicker(accessToken);
      },
    });
  }

  function showPicker(accessToken: string) {
    if (!picker) {
      const view = new google.picker.DocsView(google.picker.ViewId.FOLDERS);
      view.setSelectFolderEnabled(true);
      view.setIncludeFolders(true);
      view.setParent("root");

      picker = new google.picker.PickerBuilder()
        .addView(view)
        .setOAuthToken(accessToken)
        .setDeveloperKey(developerKey)
        .setSize(850, 500)
        .setCallback(data => {
          if (data.action === google.picker.Action.CANCEL) {
            onChange(null);
            return;
          }

          if (data.action === google.picker.Action.PICKED) {
            const { name, id } = data.docs[0];
            onChange({ parentName: `.../${name}`, parentId: id });
          }
        })
        .setAppId(appId)
        .build();
    }

    picker.setVisible(open);
  }

  return (
    <>
      <Script
        src="https://apis.google.com/js/api.js"
        async
        defer
        onLoad={onApiLoad}
        type="text/javascript"
      />
      <Script
        src="https://accounts.google.com/gsi/client"
        onLoad={gisLoaded}
        async
        defer
        type="text/javascript"
      />
    </>
  );
}

function gisLoaded() {
  gisInited = true;
}

function onApiLoad() {
  gapi.load("picker", () => {
    pickerInited = true;
  });
}
