import produce from "immer";
import { State, Step } from "../types";
import { ruleTypeMap } from "../helpers";
import { verifyIfCanSave } from "./verifyIfCanSave";
import { AccountManagement } from "pages/Rules/CloudStorage/utils";

type Payload = { currentStep: Step; action: "ADD" | "REMOVE" };

export type ToggleStorageSteps = {
  type: "toggleStorageSteps";
  payload: Payload;
};

export function toggleStorageSteps(state: State, payload: Payload) {
  const { action, currentStep } = payload;

  return produce(state, draft => {
    const ruleType = ruleTypeMap.get(state.type);
    draft.currentStep = currentStep;

    if (action === "REMOVE") {
      if (draft.type === "ZIP_ATTACHMENTS") {
        const smtpPhase = draft.rule.zipAttachments.emailFlow.smtpPhase;
        // https://mxhero.atlassian.net/browse/MXH-4962?focusedCommentId=42011

        // Si elimino el storage y es SAME password para zip attachments
        // => smtpPhase = true para mantener el estado
        if (
          smtpPhase === false &&
          draft.rule.zipAttachments.storage.syncAllByRule === true
        ) {
          // Keep as SAME
          draft.rule.zipAttachments.emailFlow.smtpPhase = true;
        }
      }

      delete draft.steps.cloudStorage;
      delete draft.steps.storageFilepath;
      delete draft.steps.moreActions;
      delete draft.storageManagement;
      delete draft.rule[ruleType].storage;
    }

    if (action === "ADD") {
      draft.steps.cloudStorage = {
        done: false,
        valid: false,
        expanded: true,
        next: "storageFilepath",
      };

      draft.steps.storageFilepath = {
        done: false,
        valid: true,
        expanded: false,
        next: null,
      };

      draft.steps.moreActions = {
        done: true,
        valid: true,
        expanded: false,
        next: null,
      };

      draft.storageManagement = AccountManagement.AUTO;

      draft.rule[ruleType].storage = {
        provider: "MXHERO_STORAGE",
        authentication: "ORGANIZATION",
        syncAllByRule: false,
        userIds: [],
        asUsers: [],
        location: {
          folderStructure: null,
        },
        override: true,
        allManagedUserIds: false,
      };

      if (draft.type === "ZIP_ATTACHMENTS") {
        const smtpPhase = draft.rule.zipAttachments.emailFlow.smtpPhase;

        if (smtpPhase) {
          // https://mxhero.atlassian.net/browse/MXH-4962?focusedCommentId=42011
          // SAME zip password =>
          //        smtpPhase  = false y el syncAllByRule = true
          draft.rule.zipAttachments.emailFlow.smtpPhase = false;
          draft.rule.zipAttachments.storage.syncAllByRule = true;
        } else {
          // INDIVIDUAL zip password
          draft.rule.zipAttachments.storage.syncAllByRule = false;
        }
      }
    }

    verifyIfCanSave(draft);
  });
}
