import { TFunction } from "i18next";
import { Step } from "pages/Rules/CreateOrEdit/context/types";
import { Rule } from "pages/Rules/types";

type StepData = {
  step: Step;
  label: string;
};

export default function steps(rule: Rule, t: TFunction): StepData[] {
  const steps: StepData[] = [
    {
      step: "action",
      label: t("action"),
    },
    {
      step: "target",
      label: t("targetStep.label"),
    },
  ];

  if (rule.actionType === "BLOCK_ATTACHMENTS") return steps;

  if (rule.actionType === "REQUEST_APPROVAL") {
    return [
      {
        step: "target",
        label: t("targetStep.label"),
      },
      {
        step: "approvalSteps",
        label: t("steps.approvalSteps"),
      },
    ];
  }

  if (rule.actionType === "SEND_DELAY") {
    return [
      {
        step: "target",
        label: t("targetStep.label"),
      },
      {
        step: "sendDelay",
        label: t("steps.sendDelay"),
      },
    ];
  }

  if (rule.actionType === "ZIP_ATTACHMENTS") {
    if (rule.zipAttachments.storage) {
      return [
        ...steps,
        {
          step: "cloudStorage",
          label: t("cloudStorage.header.title"),
        },
        {
          step: "storageFilepath",
          label: t("storageFilepath.header.title"),
        },
      ];
    }

    return steps;
  }

  return [
    ...steps,
    {
      step: "cloudStorage",
      label: t("cloudStorage.header.title"),
    },
    {
      step: "storageFilepath",
      label: t("storageFilepath.header.title"),
    },
  ];
}
