import { ChangeEventHandler, useState } from "react";
import { useTranslation } from "react-i18next";
import produce from "immer";
import { TextField } from "@material-ui/core";
import { useRuleContext } from "pages/Rules/CreateOrEdit/context/ruleContext";
import { getApprovalSteps } from "pages/Rules/CreateOrEdit/context/helpers";
import { SettingFieldsContainer } from "../styled";

import SwitchField from "components/Forms/SwitchField";
import { DoneNotification } from "@dashboard-v3/api";

export default function DoneNotificationSettings() {
  const { t } = useTranslation("rules");
  const { state, dispatch } = useRuleContext();
  const { doneNotification } = getApprovalSteps(state.rule);
  const [isEnabled, setIsEnabled] = useState(true);

  const updateSettings = (update: Partial<DoneNotification>) => {
    dispatch({
      type: "updateStep",
      payload: {
        step: "approvalSteps",
        changes: { doneNotification: update },
      },
    });
  };

  const onChange: ChangeEventHandler<HTMLInputElement> = e => {
    const { name, value } = e.target;

    const update = produce(doneNotification, draft => {
      draft[name] = value;
    });

    updateSettings(update);
  };

  const onEnableNotification: ChangeEventHandler<HTMLInputElement> = e => {
    const { checked } = e.target;
    setIsEnabled(checked);

    if (!checked) return updateSettings(null);

    updateSettings({
      title: t("approvalSteps.doneEmail.title"),
      approvedBodyText: t("approvalSteps.doneEmail.approvedBodyText"),
      rejectedBodyText: t("approvalSteps.doneEmail.rejectedBodyText"),
    });
  };

  return (
    <div>
      <SwitchField
        style={{ marginBottom: isEnabled ? 10 : 0 }}
        label={t("approvalSteps.doneNotification.enableLabel")}
        description={
          !isEnabled && t("approvalSteps.doneNotification.enableDescription")
        }
        value={isEnabled}
        onChange={onEnableNotification}
      />
      {isEnabled && (
        <div style={{ padding: "8px 16px 16px" }}>
          <SettingFieldsContainer>
            <TextField
              name="title"
              variant="outlined"
              label={t("approvalSteps.doneNotification.title")}
              value={doneNotification.title}
              onChange={onChange}
            />
            <TextField
              name="approvedBodyText"
              multiline
              minRows={4}
              variant="outlined"
              label={t("approvalSteps.doneNotification.approvalBody")}
              value={doneNotification.approvedBodyText}
              onChange={onChange}
            />
            <TextField
              name="rejectedBodyText"
              multiline
              minRows={4}
              variant="outlined"
              label={t("approvalSteps.doneNotification.rejectBody")}
              value={doneNotification.rejectedBodyText}
              onChange={onChange}
            />
          </SettingFieldsContainer>
        </div>
      )}
    </div>
  );
}
