import { useRuleContext } from "../../context/ruleContext";
import { UpdateMoreActions } from "../../context/reducers/updateMoreActions";
import { Rule } from "pages/Rules/types";
import WithForwardErrorHandling from "./WithForwardErrorHandling";
import getEmailActionType from "./getEmailActionType";
import BasicStorageErrorHandling from "./BasicStorageErrorHandling";

export default function OnStorageError() {
  const { state } = useRuleContext();

  if (!isAvailable(state.rule)) return null;

  if (supportForward(state.rule)) return <WithForwardErrorHandling />;

  return <BasicStorageErrorHandling />;
}

function isAvailable(rule: Rule) {
  const action = rule.actionType;
  const target = rule.targetType;
  const isAllowedAction =
    action === "REPLACE_ATTACHMENTS" ||
    action === "PROTECT_EMAILS" ||
    action === "ZIP_ATTACHMENTS";

  if (supportForward(rule)) return true;

  if (isAllowedAction && target === "EMAIL_FLOW") {
    return true;
  }

  return false;
}

function supportForward(rule: Rule) {
  const action = rule.actionType;
  const target = rule.targetType;

  if (
    action === "COPY_EMAILS" &&
    (target === "ARCHIVING_ADDRESS" || target === "EMAIL_FLOW")
  ) {
    return true;
  }
}

export function hasDefaultState(rule: Rule) {
  return getEmailActionType(rule) === "DEFAULT";
}

export function setToDefaults(dispatch) {
  const changes: UpdateMoreActions = {
    type: "updateMoreActions",
    payload: {
      onStorageError: { type: null },
    },
  };

  dispatch(changes);
}
