/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tooltip,
  Typography,
} from "@material-ui/core";
import ComponentList from "components/ComponentList";
import SectionHeader from "components/SectionHeader";
import apiRequest, {
  getErrorCode,
  getErrorDescription,
} from "utils/apiRequestWithErrorCode";
import Item from "./Item";
import { ReprocessEmailsSummary } from "@dashboard-v3/api";
import { StyledChipField } from "components/Forms/StyledComponents";
import ButtonWithLoading from "components/ButtonWithLoading";
import { ChipRendererArgs } from "material-ui-chip-input";
import { CSSProperties } from "styled-components";

export default function ReprocessEmails() {
  const { t } = useTranslation("reprocessEmails");
  const { enqueueSnackbar } = useSnackbar();
  const [emailSummary, setEmailSummary] = useState<ReprocessEmailsSummary>([]);
  const [loading, setLoading] = useState(false);
  const [selectToReprocess, setSelectToReprocess] = useState<
    ReprocessEmailsSummary[number] | null
  >();
  const [isOpenReprocessDialog, setOpenReprocessDialog] = useState(false);

  useEffect(() => {
    async function fetchEmailSummary() {
      setLoading(true);
      try {
        const summary = await apiRequest<ReprocessEmailsSummary>(
          "GET",
          "/storage-accounts/reprocess-emails"
        );
        setEmailSummary(summary);
      } catch (e) {
        enqueueSnackbar(t("common:errors.fetchError"), { variant: "error" });
      } finally {
        setLoading(false);
      }
    }
    if (!emailSummary.length) {
      fetchEmailSummary();
    }
  }, []);

  const getEmptyMessage = () => {
    return (
      !emailSummary.length && (
        <Box my={2}>
          <Box mb={2}>{t("list.emptyList")}</Box>
        </Box>
      )
    );
  };

  function openReprocessDialog(item: ReprocessEmailsSummary[number]) {
    setSelectToReprocess(item);
    setOpenReprocessDialog(true);
  }

  return (
    <>
      <SectionHeader title={t("header.title")}>
        <Typography>{t("header.description")}</Typography>
      </SectionHeader>
      <ComponentList
        list={emailSummary}
        loading={loading}
        emptyMsg={getEmptyMessage()}
        renderItems={summary => (
          <Item
            key={summary.storageAccountsKey}
            summary={summary}
            onReprocess={openReprocessDialog}
          />
        )}
      />
      <ReprocessDialog
        isOpen={isOpenReprocessDialog}
        item={selectToReprocess}
        onClose={() => setOpenReprocessDialog(false)}
      />
    </>
  );
}

type ErrorInAccount = {
  account: string;
  reason: "UNAUTHORIZED" | "FORBIDDEN" | "NOT_FOUND" | "UNKNOWN_ERROR";
};

function ReprocessDialog({
  isOpen,
  item,
  onClose,
}: {
  isOpen: boolean;
  item?: ReprocessEmailsSummary[number];
  onClose: () => void;
}) {
  const [accounts, setAccounts] = useState(item?.storageAccounts || []);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation(["reprocessEmails", "rules"]);
  const { enqueueSnackbar } = useSnackbar();
  const [wrongAccounts, setWrongAccounts] = useState<ErrorInAccount[]>([]);

  function addAccount(account: string) {
    setAccounts(prev => [...prev, account]);
  }

  function removeAccount(account: string) {
    setAccounts(prev => prev.filter(a => a !== account));
  }

  let isValidToReprocess = item && accounts.length > 0;

  function cleanState() {
    setAccounts([]);
    setLoading(false);
    setWrongAccounts([]);
  }

  function setErrorsInAccounts(error: unknown) {
    const { errorInAccounts } = getErrorDescription(error) as {
      errorInAccounts: ErrorInAccount[];
    };

    setWrongAccounts(errorInAccounts);
  }

  async function sendToReprocess() {
    const { storageAccountsKey } = item;
    setLoading(true);

    try {
      await apiRequest(
        "PUT",
        `/storage-accounts/reprocess-emails/${encodeURIComponent(
          storageAccountsKey
        )}`,
        {
          accounts,
          provider: item.storageType,
        }
      );
      enqueueSnackbar(t("submit.success"), { variant: "success" });

      onClose();
    } catch (error) {
      const errorCode = getErrorCode(error);
      if (errorCode === "ACCOUNTS_WITH_ERROR") {
        setErrorsInAccounts(error);
      } else {
        const msg = errorCode === "PROCESS_LIMIT_REACHED" ? "limit" : "unknown";
        enqueueSnackbar(t(`list.item.processError.${msg}`), {
          variant: "error",
        });
      }
    } finally {
      setLoading(false);
    }
  }

  function helperText() {
    if (wrongAccounts.length > 0)
      return t("rules:cloudStorage.balanced.accessError");
    if (!isValidToReprocess) return t("error.required");
    return t("input.hint");
  }

  function isErrorText() {
    if (wrongAccounts.length > 0) return true;
    if (!isValidToReprocess) return true;
    return false;
  }

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={isOpen}
      TransitionProps={{
        unmountOnExit: true,
        onExited: cleanState,
        onEnter: () => {
          setAccounts(item?.storageAccounts || []);
        },
      }}
    >
      <DialogTitle id="customized-dialog-title">
        {t("dialog.title")}
      </DialogTitle>
      <DialogContent dividers>
        <Typography style={{ marginBottom: "8px" }}>
          {t("dialog.firstHint")}
        </Typography>
        <Typography style={{ marginBottom: "20px" }}>
          {t("dialog.secondHint")}
        </Typography>

        <div style={{ paddingBottom: "20px" }}>
          <StyledChipField
            fullWidth
            variant="outlined"
            label="Storage accounts"
            value={accounts}
            onAdd={addAccount}
            onDelete={removeAccount}
            error={isErrorText()}
            helperText={helperText()}
            chipRenderer={(props, key) => (
              <AccountsValues key={key} errors={wrongAccounts} {...props} />
            )}
            required
          />
        </div>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>{t("common:cancel")}</Button>
        <ButtonWithLoading
          variant="contained"
          disableElevation
          autoFocus
          onClick={sendToReprocess}
          color="primary"
          disabled={!isValidToReprocess}
          loading={loading}
        >
          {t("dialog.save")}
        </ButtonWithLoading>
      </DialogActions>
    </Dialog>
  );
}

interface AccountsValuesProps extends ChipRendererArgs {
  errors?: ErrorInAccount[];
}

function AccountsValues(props: AccountsValuesProps) {
  const { t } = useTranslation("rules");
  const { value, errors, handleDelete } = props;
  const error = errors?.find(a => a.account === value);
  const commonStyles: CSSProperties = {
    margin: "0 8px 8px 0",
    cursor: "pointer",
  };

  if (error) {
    return (
      <Tooltip
        title={t(`cloudStorage.asUser.error.${error.reason}`, {
          account: value,
        })}
      >
        <Chip
          color="secondary"
          label={value}
          onDelete={handleDelete}
          style={commonStyles}
        />
      </Tooltip>
    );
  }
  return <Chip label={value} onDelete={handleDelete} style={commonStyles} />;
}
