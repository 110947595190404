import { Box, MenuItem } from "@material-ui/core";
import SelectField from "components/Forms/SelectField";
import { useRuleContext } from "../../CreateOrEdit/context/ruleContext";
import { Label, OptionGroup } from "components/Styled";
import { useTranslation } from "react-i18next";
import { getStorage } from "../utils";
import { getEmailFlow } from "pages/Rules/CreateOrEdit/context/helpers";

import { State } from "pages/Rules/CreateOrEdit/context/types";
import { Rule } from "pages/Rules/types";

export function canTrackEmails(state: State): boolean {
  if (!validRuleType(state.rule)) return false;

  const emailFlow = getEmailFlow(state.rule);
  if (emailFlow.bidirectional) return true;
  // Or
  const fromOption = state.checks?.emailFlow?.fromOption;
  return Boolean(fromOption && fromOption.managed);
}

function validRuleType(rule: Rule) {
  return (
    (rule.actionType === "REPLACE_ATTACHMENTS" ||
      rule.actionType === "PROTECT_EMAILS") &&
    rule.targetType === "EMAIL_FLOW"
  );
}

export default function TrackingEmails() {
  const { t } = useTranslation("rules");
  const { state, dispatch } = useRuleContext();
  const storage = getStorage(state.rule);

  return (
    <OptionGroup data-testid="TrackingEmails">
      <Label wording={t("trackingEmails.label")} />
      <Box mt={2}>
        <SelectField
          name="templatePolicy"
          value={storage.trackingType ?? "NONE"}
          options={[...options]}
          renderOption={option => (
            <MenuItem key={option} value={option}>
              {t(`trackingEmails.${option}`) as string}
            </MenuItem>
          )}
          onChange={event => {
            const option = event.target.value as (typeof options)[number];
            dispatch({
              type: "updateStep",
              payload: {
                step: "cloudStorage",
                changes: {
                  trackingType: option,
                },
              },
            });
          }}
        />
      </Box>
    </OptionGroup>
  );
}

const options = ["NONE", "OPEN_EMAIL", "OPEN_LINK", "BOTH"] as const;
