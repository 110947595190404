import { Rule } from "pages/Rules/types";
import { State, Step } from "./types";

export default function initSteps(
  rule: Rule
): { steps: State["steps"]; currentStep: Step } | null {
  if (!rule) return null;

  const isEdit = Boolean(rule.id);

  if (rule.actionType === "BLOCK_ATTACHMENTS") {
    return blockAttachmentSteps(isEdit);
  }

  if (rule.actionType === "REQUEST_APPROVAL") {
    return requestApproval(isEdit);
  }

  if (rule.actionType === "SEND_DELAY") {
    return sendDelay(isEdit);
  }

  if (rule.actionType === "ZIP_ATTACHMENTS") {
    return zipAttachments(rule);
  }

  if (isEdit) {
    return {
      steps: {
        action: { done: true, valid: true, expanded: true, next: "target" },
        target: {
          done: true,
          valid: true,
          expanded: true,
          next: "cloudStorage",
        },
        cloudStorage: {
          done: true,
          valid: true,
          expanded: true,
          next: "storageFilepath",
        },
        storageFilepath: {
          done: true,
          valid: true,
          expanded: true,
          next: null,
        },
        moreActions: {
          done: true,
          valid: true,
          expanded: false,
          next: null,
        },
      } as State["steps"],
      currentStep: "storageFilepath" as Step,
    };
  }
  return {
    steps: {
      action: { done: false, valid: true, expanded: true, next: "target" },
      target: {
        done: false,
        valid: false,
        expanded: false,
        next: "cloudStorage",
      },
      cloudStorage: {
        done: false,
        valid: false,
        expanded: false,
        next: "storageFilepath",
      },
      storageFilepath: {
        done: false,
        valid: true,
        expanded: false,
        next: null,
      },
      moreActions: {
        done: true,
        valid: true,
        expanded: false,
        next: null,
      },
    } as State["steps"],
    currentStep: "action" as Step,
  };
}

function blockAttachmentSteps(isEdit: boolean) {
  if (isEdit) {
    return {
      steps: {
        action: { done: true, valid: true, expanded: true, next: "target" },
        target: { done: true, valid: true, expanded: true, next: null },
      } as State["steps"],
      currentStep: "target" as Step,
    };
  }

  return {
    steps: {
      action: { done: false, valid: true, expanded: true, next: "target" },
      target: { done: false, valid: false, expanded: false, next: null },
    } as State["steps"],
    currentStep: "action" as Step,
  };
}

function requestApproval(isEdit: boolean) {
  if (isEdit) {
    return {
      steps: {
        target: {
          done: true,
          valid: true,
          expanded: true,
          next: "approvalSteps",
        },
        approvalSteps: { done: true, valid: true, expanded: true, next: null },
      } as State["steps"],
      currentStep: "approvalSteps" as Step,
    };
  }

  return {
    steps: {
      target: {
        done: false,
        valid: false,
        expanded: true,
        next: "approvalSteps",
      },
      approvalSteps: { done: false, valid: false, expanded: false, next: null },
    } as State["steps"],
    currentStep: "target" as Step,
  };
}

function sendDelay(isEdit: boolean) {
  if (isEdit) {
    return {
      steps: {
        target: {
          done: true,
          valid: true,
          expanded: true,
          next: "sendDelay",
        },
        sendDelay: { done: true, valid: true, expanded: true, next: null },
      } as State["steps"],
      currentStep: "sendDelay" as Step,
    };
  }

  return {
    steps: {
      target: {
        done: false,
        valid: false,
        expanded: true,
        next: "sendDelay",
      },
      sendDelay: { done: false, valid: true, expanded: false, next: null },
    } as State["steps"],
    currentStep: "target" as Step,
  };
}

function zipAttachments(rule: Rule) {
  const isEdit = Boolean(rule.id);

  if (isEdit) {
    return {
      currentStep: "storageFilepath" as Step,
      steps: {
        action: { done: true, valid: true, expanded: true, next: "target" },
        target: { done: true, valid: true, expanded: true, next: null },
        ...(rule.zipAttachments.storage && {
          cloudStorage: {
            done: true,
            valid: true,
            expanded: true,
            next: "storageFilepath",
          },
          storageFilepath: {
            done: true,
            valid: true,
            expanded: true,
            next: null,
          },
          moreActions: {
            done: true,
            valid: true,
            expanded: false,
            next: null,
          },
        }),
      } as State["steps"],
    };
  }

  return {
    currentStep: "action" as Step,
    steps: {
      action: { done: false, valid: true, expanded: true, next: "target" },
      target: { done: false, valid: false, expanded: false, next: null },
    } as State["steps"],
  };
}
