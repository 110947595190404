import { useTranslation } from "react-i18next";
import { TableCell, TableRow, Typography } from "@material-ui/core";
import Affected from "./Affected";
import { EventItem } from "@dashboard-v3/api";
import DownloadMenu from "./DownloadMenu";

export default function Item({ item }: { item: EventItem }) {
  const { t } = useTranslation("userActivity");
  const { createdAt, user } = item;
  const { type } = item.event;
  const createdDate = new Date(createdAt);

  return (
    <TableRow data-testid="user-activity-item">
      <TableCell>
        <Typography>{user}</Typography>
      </TableCell>
      <TableCell>
        <Typography>{t(`events.${type}`)}</Typography>
      </TableCell>
      <TableCell>
        <Affected userEvent={item} />
      </TableCell>
      <TableCell>
        <Typography>
          {createdDate.toLocaleDateString([], {
            day: "numeric",
            month: "short",
            year: "numeric",
          })}
        </Typography>
        <Typography color="textSecondary" variant="body2">
          {createdDate.toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
          })}
        </Typography>
      </TableCell>
      <TableCell style={{ padding: 0 }} align="right">
        <DownloadMenu item={item} />
      </TableCell>
    </TableRow>
  );
}
