import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Button, Grid, Container, Typography, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import FlipToFrontIcon from "@material-ui/icons/FlipToFront";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import BeenhereIcon from "@material-ui/icons/Beenhere";
import SectionHeader from "components/SectionHeader";
import DescriptionBlock from "components/DescriptionBlock";
import ActionButton from "./ActionButton";
import { ActionIcon } from "./ActionIcon";
import { RuleType } from "pages/Rules/types";

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(3),
    textAlign: "center",
  },
}));

export default function ActionTypeSelectionPage() {
  const { t } = useTranslation("rules");
  const [ruleType, setRuleType] = useState<RuleType>();
  const classes = useStyles();
  const history = useHistory();

  const handleConfirmSelection = () => {
    history.push(`/rules/${ruleType.toLowerCase()}/create`);
  };

  return (
    <>
      <SectionHeader title={t("createARule")}>
        <Typography>{t("rulesDescription")}</Typography>
      </SectionHeader>
      <Container maxWidth="md">
        <Paper className={classes.paper}>
          <Typography style={{ marginBottom: "20px" }} variant="h6">
            {t("whatToDo")}
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={3}>
              <ActionButton
                action="REPLACE_ATTACHMENTS"
                active={ruleType === "REPLACE_ATTACHMENTS"}
                onClick={setRuleType}
                icon={() => <ActionIcon type="REPLACE_ATTACHMENTS" />}
              />
            </Grid>
            <Grid item xs={3}>
              <ActionButton
                action="DRAG_AND_DROP"
                active={ruleType === "DRAG_AND_DROP"}
                onClick={setRuleType}
                icon={() => <ActionIcon type="DRAG_AND_DROP" />}
              />
            </Grid>
            <Grid item xs={3}>
              <ActionButton
                action="COPY_EMAILS"
                active={ruleType === "COPY_EMAILS"}
                onClick={setRuleType}
                icon={FileCopyIcon}
              />
            </Grid>
            <Grid item xs={3}>
              <ActionButton
                action="MOVE_EMAILS"
                active={ruleType === "MOVE_EMAILS"}
                onClick={setRuleType}
                icon={FlipToFrontIcon}
              />
            </Grid>
            <Grid item xs={3}>
              <ActionButton
                action="PROTECT_EMAILS"
                active={ruleType === "PROTECT_EMAILS"}
                onClick={setRuleType}
                icon={() => <ActionIcon type="PROTECT_EMAILS" />}
              />
            </Grid>
            <Grid item xs={3}>
              <ActionButton
                action="BLOCK_ATTACHMENTS"
                active={ruleType === "BLOCK_ATTACHMENTS"}
                onClick={setRuleType}
                icon={LockOutlinedIcon}
              />
            </Grid>
            <Grid item xs={3}>
              <ActionButton
                action="REQUEST_APPROVAL"
                active={ruleType === "REQUEST_APPROVAL"}
                onClick={setRuleType}
                icon={BeenhereIcon}
              />
            </Grid>
            <Grid item xs={3}>
              <ActionButton
                action="SEND_DELAY"
                active={ruleType === "SEND_DELAY"}
                onClick={setRuleType}
                icon={() => <SendDelayIcon />}
              />
            </Grid>
            <Grid item xs={3}>
              <ActionButton
                action="ZIP_ATTACHMENTS"
                active={ruleType === "ZIP_ATTACHMENTS"}
                onClick={setRuleType}
                icon={() => <ZipAttachmentsIcon />}
              />
            </Grid>
          </Grid>
          {ruleType && (
            <DescriptionBlock
              title={`${t(`actions.${ruleType}.title`)} ${t("action")}`}
            >
              {t(`actions.${ruleType}.description`)}
            </DescriptionBlock>
          )}
          <Button
            color="primary"
            data-testid="rules__start-rule-btn"
            disableElevation
            disabled={!ruleType}
            fullWidth
            onClick={handleConfirmSelection}
            size="large"
            style={{ marginTop: "30px" }}
            variant="contained"
          >
            {t("startRuleCreation")}
          </Button>
        </Paper>
      </Container>
    </>
  );
}

const SendDelayIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="1.8"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
    <path d="M13 19h-8a2 2 0 0 1 -2 -2v-10a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v6" />
    <path d="M3 7l9 6l9 -6" />
    <path d="M17 17v5" />
    <path d="M21 17v5" />
  </svg>
);

const ZipAttachmentsIcon = () => {
  return (
    <svg
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        stroke="currentColor"
        stroke-linecap="round"
        stroke-width="1.8"
        d="M10 3v4a1 1 0 0 1-1 1H5m14-4v16a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V7.914a1 1 0 0 1 .293-.707l3.914-3.914A1 1 0 0 1 9.914 3H18a1 1 0 0 1 1 1Zm-4 1h.01v.01H15V5Zm-2 2h.01v.01H13V7Zm2 2h.01v.01H15V9Zm-2 2h.01v.01H13V11Zm2 2h.01v.01H15V13Zm-2 2h.01v.01H13V15Zm2 2h.01v.01H15V17Zm-2 2h.01v.01H13V19Z"
      />
    </svg>
  );
};
