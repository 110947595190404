import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import {
  List,
  ListItem,
  Paper,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { EmptyListContainer } from "components/ComponentList";
import SwitchField from "components/Forms/SwitchField";
import { SwitchSkeleton } from "components/SkeletonLoading";
import useOrganization from "utils/useOrganization";
import HeaderAndFooter from "./HeaderAndFooter";
import EmbeddedLinkText from "./EmbeddedLinkText";
import IgnoreTracking from "./IgnoreTracking";
import * as api from "../api";

import { ResponseOrganization, StorageSettings } from "@dashboard-v3/api";

interface Props {
  settings: StorageSettings;
  onChange: (update: StorageSettings) => void;
}

export default function Settings({ settings, onChange: emitChange }: Props) {
  const { t } = useTranslation("storage");
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const { organization, updateOrganization, isLoading } = useOrganization();
  const [loadingReadableText, setLoadingReadableText] = useState(false);
  const [loadingOnedriveLink, setLoadingOnedriveLink] = useState(false);
  const [loadingTrackingReport, setLoadingTrackingReport] = useState(false);
  const [onedriveLink, setOnedriveLink] = useState(initOnedrive(organization));

  const updateReadableText = async () => {
    const newValue = !settings.readableText;
    emitChange({ ...settings, readableText: newValue });
    setLoadingReadableText(true);
    try {
      await api.updateReadableText(newValue);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingReadableText(false);
    }
  };

  const updateTrackingReport = async () => {
    const newValue = !settings.trackingReportDisabled;
    emitChange({ ...settings, trackingReportDisabled: newValue });
    setLoadingTrackingReport(true);
    try {
      // await api.updateReadableText(newValue);
      await updateOrganization({
        storagesPolicy: { trackingReportDisabled: newValue },
      });
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingTrackingReport(false);
    }
  };

  async function saveHeaderOrFooter(header: string, footer: string) {
    try {
      await api.updateHeaderAndFooter(header, footer);

      emitChange({
        ...settings,
        attachmentsHeader: header,
        attachmentsFooter: footer,
      });
    } catch (error) {
      console.error(error);
      enqueueSnackbar(t("error.saveHeaderOrFooter"), { variant: "error" });
    }
  }

  const handleOneDriveLegacyLink = async e => {
    const { checked } = e.target;
    setOnedriveLink(checked);
    setLoadingOnedriveLink(true);
    try {
      await updateOrganization({
        storagesPolicy: { onedriveLegacyDownloadLink: checked },
      });
    } catch (error) {
      // Revert change
      setOnedriveLink(!checked);
      throw error;
    } finally {
      setLoadingOnedriveLink(false);
    }
  };

  if (!settings) {
    return (
      <EmptyListContainer data-testid="empty-list__message">
        <Typography component="div">
          {t("error.fetchStorageSettings")}
        </Typography>
      </EmptyListContainer>
    );
  }

  return (
    <>
      <List className={classes.contentList} component={Paper}>
        <HeaderAndFooter
          footer={settings.attachmentsFooter}
          header={settings.attachmentsHeader}
          onSave={saveHeaderOrFooter}
        />
      </List>
      <List className={classes.contentList} component={Paper}>
        <EmbeddedLinkText />
      </List>
      <List className={classes.contentList} component={Paper}>
        <ListItem className={classes.toggleOption}>
          <SwitchSkeleton loading={isLoading}>
            <SwitchField
              name="oneDriveLegacyLink"
              label={t("oneDriveLegacyLink.label")}
              description={t("oneDriveLegacyLink.description")}
              value={onedriveLink}
              disabled={loadingOnedriveLink}
              onChange={handleOneDriveLegacyLink}
            />
          </SwitchSkeleton>
        </ListItem>
        <ListItem className={classes.toggleOption}>
          <SwitchField
            name="readableText"
            label={t("readableText")}
            description={t("readableTextExplanation")}
            value={settings.readableText}
            disabled={loadingReadableText}
            onChange={updateReadableText}
          />
        </ListItem>
        <ListItem
          className={classes.toggleOption}
          style={{ marginBottom: "10px" }}
        >
          <SwitchField
            name="trackingReporDisabled"
            label={t("trackingReporDisabled")}
            description={t("trackingReporDisabledExplanation")}
            value={settings.trackingReportDisabled}
            disabled={loadingTrackingReport}
            onChange={updateTrackingReport}
          />
        </ListItem>
      </List>
      <List className={classes.contentList} component={Paper}>
        <IgnoreTracking />
      </List>
    </>
  );
}

const initOnedrive = (org: ResponseOrganization) => () =>
  org?.storagesPolicy?.onedriveLegacyDownloadLink || false;

const useStyles = makeStyles({
  contentList: {
    marginTop: "20px",
  },
  toggleOption: {
    display: "block",
    maxWidth: "70%",
    marginLeft: "10px",
  },
});
