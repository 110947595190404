import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, ListItem, ListItemText } from "@material-ui/core";
import ButtonWithLoading from "components/ButtonWithLoading";
import { StyledChipField } from "components/Forms/StyledComponents";
import useOrganization from "utils/useOrganization";

import { ResponseOrganization } from "@dashboard-v3/api";

type State = { value: string[]; isSaving: boolean; error: string };

export default function IgnoreTracking() {
  const { t } = useTranslation("storage");
  const { isLoading, organization, updateOrganization } = useOrganization();
  const [state, setState] = useState<State>(initState(organization));
  const isSaveDisabled =
    isLoading ||
    (!organization.storagesPolicy.ignoreTrackingIpMasks?.length &&
      !state.value.length);

  function handleChange(isAdd: boolean) {
    return (ip: string) => {
      const isValidMask = validateIpMask(ip);

      setState(prev => {
        if (!isValidMask) {
          return { ...prev, error: t("ignoreTrackinIpMask.field.invalid") };
        }

        if (isAdd) return { ...prev, error: "", value: [...prev.value, ip] };

        return {
          ...prev,
          error: "",
          value: prev.value.filter(item => item !== ip),
        };
      });
    };
  }

  async function handleSave() {
    setState({ ...state, isSaving: true });
    const storagesPolicy = { ignoreTrackingIpMasks: state.value };
    await updateOrganization({ storagesPolicy });
    setState({ ...state, isSaving: false });
  }

  return (
    <ListItem style={{ flexDirection: "column", alignItems: "stretch" }}>
      <ListItemText
        primary={t("ignoreTrackinIpMask.title")}
        secondary={t("ignoreTrackinIpMask.subtitle")}
        primaryTypographyProps={{ variant: "subtitle2" }}
        secondaryTypographyProps={{ variant: "caption" }}
      />
      <StyledChipField
        variant="outlined"
        fullWidth
        label={t("ignoreTrackinIpMask.field.label")}
        value={state.value}
        onAdd={handleChange(true)}
        onDelete={handleChange(false)}
        allowDuplicates={false}
        disabled={isLoading || state.isSaving}
        error={Boolean(state.error)}
        helperText={state.error}
      />
      <Box marginTop="20px" textAlign="left" width="100%">
        <ButtonWithLoading
          variant="contained"
          size="small"
          color="primary"
          disableElevation
          loading={isLoading || state.isSaving}
          disabled={isSaveDisabled}
          onClick={handleSave}
        >
          {t("common:save")}
        </ButtonWithLoading>
      </Box>
    </ListItem>
  );
}

function initState(organization: ResponseOrganization) {
  const storagesPolicy = organization.storagesPolicy;

  return (): State => ({
    isSaving: false,
    error: "",
    value: storagesPolicy?.ignoreTrackingIpMasks || [],
  });
}

function validateIpMask(ip: string) {
  const regex =
    /^([01]?\d\d?|2[0-4]\d|25[0-5])(?:\.(?:[01]?\d\d?|2[0-4]\d|25[0-5])){3}(?:\/[0-2]\d|\/3[0-2])?$/;

  return regex.test(ip);
}
