import produce from "immer";
import { useTranslation } from "react-i18next";
import { Label, OptionGroup } from "components/Styled";
import ButtonWithLoading from "components/ButtonWithLoading";
import StorageAccountSelector, { Change } from "./StorageAccountSelector";
import AsUserSelector from "./AsUserSelector";
import SelectSecurityOptions from "./SelectSecurityOptions";
import { ContentWrapper } from "./Styled";
import { useRuleContext } from "../CreateOrEdit/context/ruleContext";
import {
  canConfirm,
  canImpersonate,
  getStorage,
  showSecurityOptionsIn,
} from "./utils";

import { Storage } from "@dashboard-v3/api";
import { SecurityOption } from "./types";

interface StorageAccountProps {
  securityOptions: SecurityOption[];
}

export default function StorageAccount({
  securityOptions,
}: StorageAccountProps) {
  const { t } = useTranslation("rules");
  const { state, dispatch } = useRuleContext();
  const { rule } = state;
  const storage: Storage = getStorage(rule);

  function handleStorageAccountChange(storageAccountChange: Change) {
    const { authentication, provider, userId } = storageAccountChange;

    const changes = produce(storage, draft => {
      if (authentication === "INDIVIDUAL") {
        draft.userIds = [];
        draft.asUsers = provider === "DROPBOX" ? draft.asUsers : [];
      }

      draft.provider = provider;
      draft.authentication = authentication;
      draft.asUser = null;
      draft.userId = userId || "";
      draft.storeIdUserId = null;
      draft.storeId = null;
      if (draft.location.parentId) {
        delete draft.location.parentId;
        delete draft.location.parentName;
      }

      if (provider === "MXHERO_STORAGE") {
        // default security option set
        draft.linkSecurity = {
          id: "OPEN_LINKS_7_DAYS",
          type: "DEFINITION",
        };
      } else {
        draft.linkSecurity = null;
      }
    });
    dispatch({ type: "checkFolderCollaboration", payload: { provider } });
    dispatch({
      type: "updateStep",
      payload: { step: "cloudStorage", changes },
    });
  }

  const showAsUserSelector = () => {
    const { type } = state;

    if (type === "ZIP_ATTACHMENTS") return false;

    return canImpersonate(storage);
  };

  return (
    <ContentWrapper>
      <OptionGroup>
        <Label wording={t("cloudStorage.accounts.title")} />
        <StorageAccountSelector
          storage={storage}
          onChange={handleStorageAccountChange}
        />
      </OptionGroup>
      {showAsUserSelector() && <AsUserSelector key={storage.provider} />}
      {showSecurityOptionsIn("STEP", state.rule) && (
        <SelectSecurityOptions options={securityOptions} />
      )}
      <ButtonWithLoading
        variant="contained"
        disableElevation
        fullWidth
        size="large"
        loading={state.asyncStatus === "validate_storage_access"}
        disabled={!canConfirm(state)}
        onClick={() => {
          dispatch({ type: "confirmStep", payload: "cloudStorage" });
        }}
      >
        {t("confirm")}
      </ButtonWithLoading>
    </ContentWrapper>
  );
}
