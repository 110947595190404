import { State } from "../types";
import { SendDelay } from "@dashboard-v3/api";
import produce from "immer";
import validateStep from "./validateStep";
import { WritableDraft } from "immer/dist/internal";

export type UpdateSendDelay = {
  type: "updateSendDelay";
  payload: Payload;
};

type Payload = Partial<{
  expiration: SendDelay["expiration"];
  senderNotification: SendDelay["senderNotification"];
}>;

export function updateSendDelay(state: State, payload: Payload) {
  return produce(state, draft => {
    if ("expiration" in payload) {
      updateExpiration(draft, payload);
    }

    if ("senderNotification" in payload) {
      updateSenderNotification(draft, payload);
    }

    validateStep("sendDelay", draft);
  });
}

function updateExpiration(draft: WritableDraft<State>, payload: Payload) {
  const type = payload.expiration?.type;
  const value = payload.expiration?.value;

  const sendDelayExpiration = draft.rule.sendDelay.expiration;

  if (type) {
    sendDelayExpiration.type = type;
    draft.steps.sendDelay.done = false;
  }

  if (value !== undefined) {
    sendDelayExpiration.value = value;
    if (isNaN(value)) {
      sendDelayExpiration.value = null;
    }
    draft.steps.sendDelay.done = false;
  }
}

function updateSenderNotification(
  draft: WritableDraft<State>,
  payload: Payload
) {
  const sendDelay = draft.rule.sendDelay;

  draft.steps.sendDelay.done = false;

  if (payload.senderNotification === null) {
    return delete sendDelay.senderNotification;
  }

  sendDelay.senderNotification = {
    ...payload.senderNotification,
    accessLink: false,
    accessLinkLabel: "",
    cancelLink: false,
    cancelLinkLabel: "",
  };
}
