import { PropsWithChildren, useState } from "react";
import { useTranslation } from "react-i18next";
import { MenuItem } from "@material-ui/core";
import { FieldRow, OptionDescription } from "components/Forms/StyledComponents";
import SearchInput from "components/AutocompleteSearchInput";
import SelectField from "components/Forms/SelectField";
import { isConvertAction } from "pages/Variables/helpers";
import useVariables from "utils/useVariables";
import { memoize } from "utils/regExp";
import * as regex from "utils/regExp";
import { useConditionsCtx } from "../../state/ConditionsProvider";
import { SearchValueHandler } from "../../types";
import { DashboardVariable } from "types";
import {
  Dataset,
  KeyedAndChild,
  KeyedAndChildParameters,
} from "@dashboard-v3/api";
import { SelectInputProps } from "@material-ui/core/Select/SelectInput";
import { Draft } from "immer";

type ActionControlProps = PropsWithChildren<{
  condition: boolean;
}>;

type ActionFieldProps = {
  actionType: "primary" | "secondary";
  actionOpts: Array<"convert" | "map" | "and">;
  datasets?: Dataset[];
  searchValues?: () => DashboardVariable[];
  onAction: SelectInputProps["onChange"];
};

const ActionControl = ({ condition, children }: ActionControlProps) => {
  return condition ? <>{children}</> : null;
};

const getRegexGroups = memoize(regex.getRegexGroups);

const ConvertFields = ({
  actionType,
  actionOpts,
  searchValues,
  onAction,
}: ActionFieldProps) => {
  const { t } = useTranslation("variables");
  const { mapVarToLabel, mapVarToValue } = useVariables();

  const { actions, updateCondition, selectFromCondition, isReadOnly } =
    useConditionsCtx();
  const { toValue } = selectFromCondition();
  const toValueOptions = searchValues();

  const [prevToValue, setPrevToValue] = useState(toValue);
  const [valueMask, setValueMask] = useState<string>(
    mapVarToLabel(toValue, toValueOptions)
  );

  if (prevToValue !== toValue) {
    const toValueOptions = searchValues();
    setValueMask(mapVarToLabel(toValue, toValueOptions));
    setPrevToValue(toValue);
  }

  const handleToValue: SearchValueHandler = (value, searchValues) => {
    setValueMask(value);
    updateCondition({ toValue: mapVarToValue(value, searchValues) });
  };

  return (
    <FieldRow>
      <SelectField
        name={actionType}
        label={t("form.action.label")}
        options={actionOpts}
        value={actions[actionType]}
        onChange={onAction}
        required
        disabled={isReadOnly}
        renderOption={action => (
          <MenuItem key={action} value={action}>
            {t(`form.action.option.${action}`)}
          </MenuItem>
        )}
      />
      {isConvertAction(actions[actionType]) && (
        <SearchInput
          name="toValue"
          variables={toValueOptions}
          placeholder={t("form.actionValue.label")}
          value={valueMask}
          onChange={handleToValue}
          fullWidth
          required
          disabled={isReadOnly}
        />
      )}
    </FieldRow>
  );
};

const MapFields = ({
  actionType,
  actionOpts,
  datasets,
  onAction,
}: ActionFieldProps) => {
  const { t } = useTranslation("variables");
  const {
    updateCondition,
    actions,
    handleDatasetId,
    selectFromCondition,
    isReadOnly,
  } = useConditionsCtx();
  const { parameters, dataSetId } = selectFromCondition();
  const { mapVarToLabel, mapVarToValue, platformVariables } = useVariables();

  const getKeyMatchSearch = () => {
    const groups = getRegexGroups(parameters[0] || "");

    if (groups.length) {
      const regexOptions = groups.map(
        (group, i) =>
          ({
            id: `{group-${i}}`,
            origin: "USER",
            name: t(`form.keyMatch.searchValues`, {
              group: i,
            }),
            description: group.map((val, i) => (
              <OptionDescription key={`${i}`} highlight={val.group}>
                {val.text}
              </OptionDescription>
            )),
          } as DashboardVariable)
      );

      return regexOptions.concat(platformVariables);
    }
    return platformVariables;
  };

  const keyMatchSearch = getKeyMatchSearch();

  const handleKeyMatch: SearchValueHandler = value => {
    // const groups = getKeyMatchSearch();
    const groups = keyMatchSearch;
    updateCondition((prev: Draft<KeyedAndChild>) => {
      const { parameters } = prev;
      parameters.splice(1, 1, mapVarToValue(value, groups));
      return { ...prev, parameters };
    });
  };

  function changeDatasetMatchType(event) {
    const value = event.target.value as "contains" | "equals";
    updateCondition((prev: Draft<KeyedAndChild>) => {
      const parameters = prev.parameters as KeyedAndChildParameters;
      /*
      Check https://mxhero.atlassian.net/browse/MXH-4920
      
      parameters:
      position 0: regex input value
      position 1: dataset keyMatch
      position 2: dataset match type ("contains" or "equals"). "equals" If is not present 
      */

      if (parameters.length === 3) {
        parameters[2] = value;
      }

      if (parameters.length === 1) {
        parameters.push("");
      }

      if (parameters.length === 2) {
        parameters.push(value);
      }

      return { ...prev, parameters };
    });
  }

  return (
    <>
      <FieldRow>
        <SelectField
          name={actionType}
          label={t("form.action.label")}
          options={actionOpts}
          value={actions[actionType]}
          onChange={onAction}
          required
          disabled={isReadOnly}
          renderOption={action => (
            <MenuItem key={action} value={action}>
              {t(`form.action.option.${action}`)}
            </MenuItem>
          )}
        />
        {datasets && (
          <SelectField
            name="dataSetId"
            label={t("form.datasetId.label")}
            options={datasets}
            value={dataSetId || ""}
            onChange={handleDatasetId}
            required
            disabled={isReadOnly}
            renderOption={({ id, name }) => (
              <MenuItem key={id} value={id}>
                {name}
              </MenuItem>
            )}
          />
        )}
      </FieldRow>
      <FieldRow>
        <SelectField
          name="datasetMatchType"
          options={["equals", "contains"]}
          value={parameters.length === 3 ? parameters[2] : "equals"}
          onChange={changeDatasetMatchType}
          renderOption={matchType => (
            <MenuItem key={matchType} value={matchType}>
              {matchType === "equals"
                ? t("form.keyMatch.title")
                : t("form.keyContains.title")}
            </MenuItem>
          )}
        />
        <SearchInput
          name="keyMatch"
          variables={keyMatchSearch}
          onChange={handleKeyMatch}
          value={mapVarToLabel(parameters[1], keyMatchSearch)}
          fullWidth
          required
          disabled={isReadOnly}
        />
      </FieldRow>
    </>
  );
};

export { ActionControl, ConvertFields, MapFields };
