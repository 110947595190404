import { ChangeEventHandler, useState } from "react";
import produce from "immer";
import { useTranslation } from "react-i18next";
import { TextField } from "@material-ui/core";
import SwitchField from "components/Forms/SwitchField";
import { SettingFieldsContainer, SettingsSwitchContainer } from "../styled";
import { getApprovalSteps } from "../../../context/helpers";
import { useRuleContext } from "../../../context/ruleContext";

import { SenderNotification } from "@dashboard-v3/api";

export default function SenderSettings() {
  const { t } = useTranslation("rules");
  const { state, dispatch } = useRuleContext();
  const { senderNotification } = getApprovalSteps(state.rule);
  const [isEnabled, setIsEnabled] = useState(true);
  const recipient = t("approvalSteps.recipientType.sender");
  const linkLabelDefaults = {
    accessLinkLabel: t("approvalSteps.senderEmail.access"),
    cancelLinkLabel: t("approvalSteps.senderEmail.cancel"),
  };

  const updateSettings = (update: Partial<SenderNotification>) => {
    dispatch({
      type: "updateStep",
      payload: {
        step: "approvalSteps",
        changes: { senderNotification: update },
      },
    });
  };

  const handleNotificationText: ChangeEventHandler<HTMLInputElement> = e => {
    const { name, value } = e.target;
    const update = produce(senderNotification, draft => {
      draft[name] = value;
    });

    updateSettings(update);
  };

  const handleNotificationLinks: ChangeEventHandler<HTMLInputElement> = e => {
    const { name, checked } = e.target;
    const update = produce(senderNotification, draft => {
      const linkLabelKey = `${name}Label`;

      draft[name] = checked;

      if (checked && !draft[linkLabelKey]) {
        draft[linkLabelKey] = linkLabelDefaults[linkLabelKey];
      }

      if (!checked) {
        draft[linkLabelKey] = "";
      }
    });

    updateSettings(update);
  };

  const onEnableNotification: ChangeEventHandler<HTMLInputElement> = e => {
    const { checked } = e.target;
    setIsEnabled(checked);

    if (!checked) return updateSettings(null);

    updateSettings({
      accessLink: false,
      accessLinkLabel: t("approvalSteps.senderEmail.access"),
      bodyText: t("approvalSteps.senderEmail.body"),
      cancelLink: true,
      cancelLinkLabel: t("approvalSteps.senderEmail.cancel"),
      title: t("approvalSteps.senderEmail.title"),
    });
  };

  return (
    <div style={{ marginBottom: 10 }}>
      <SwitchField
        label={t("approvalSteps.enableLabel", { recipient })}
        value={isEnabled}
        onChange={onEnableNotification}
        style={{ marginBottom: isEnabled ? 10 : 0 }}
        description={
          !isEnabled && t("approvalSteps.senderNotification.enableDescription")
        }
      />
      {isEnabled && (
        <div style={{ padding: "8px 16px 16px" }}>
          <SettingFieldsContainer>
            <TextField
              name="title"
              variant="outlined"
              label={t("approvalSteps.title", { recipient })}
              value={senderNotification.title}
              onChange={handleNotificationText}
            />
            <TextField
              name="bodyText"
              multiline
              minRows={4}
              variant="outlined"
              label={t("approvalSteps.bodyText", { recipient })}
              value={senderNotification.bodyText}
              onChange={handleNotificationText}
            />
            <SettingsSwitchContainer>
              <SwitchField
                name="accessLink"
                label={t("approvalSteps.accessLink", { recipient })}
                description={t("approvalSteps.accessLinkDesc")}
                value={senderNotification.accessLink}
                onChange={handleNotificationLinks}
              />
              {senderNotification.accessLink && (
                <TextField
                  name="accessLinkLabel"
                  variant="outlined"
                  label={t("approvalSteps.accessLinkLabel")}
                  value={senderNotification.accessLinkLabel}
                  onChange={handleNotificationText}
                />
              )}
            </SettingsSwitchContainer>
            <SettingsSwitchContainer>
              <SwitchField
                name="cancelLink"
                label={t("approvalSteps.cancelLink", { recipient })}
                description={t("approvalSteps.cancelLinkDesc")}
                value={senderNotification.cancelLink}
                onChange={handleNotificationLinks}
              />
              {senderNotification.cancelLink && (
                <TextField
                  name="cancelLinkLabel"
                  variant="outlined"
                  label={t("approvalSteps.cancelLinkLabel")}
                  value={senderNotification.cancelLinkLabel}
                  onChange={handleNotificationText}
                />
              )}
            </SettingsSwitchContainer>
          </SettingFieldsContainer>
        </div>
      )}
    </div>
  );
}
