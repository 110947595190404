import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import { Rule } from "pages/Rules/types";
import { useTranslation } from "react-i18next";

interface Props {
  isOpen: boolean;
  actionType: Rule["actionType"];
  onClose: (accept: boolean) => void;
}

export default function ConfirmChangetisInActionModal({
  isOpen,
  actionType,
  onClose,
}: Props) {
  const { t } = useTranslation("rules");

  return (
    <Dialog open={isOpen} onClose={() => onClose(false)}>
      <DialogTitle>{t("changesInActionModal.title")}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t(`changesInActionModal.${actionType}.content`)}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose(false)} color="primary">
          {t("changesInActionModal.changeStorageBtn")}
        </Button>
        <Button
          onClick={() => onClose(true)}
          color="primary"
          variant="contained"
          disableElevation
          autoFocus
        >
          {t("common:accept")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
