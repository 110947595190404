import { ChangeEventHandler, useState } from "react";
import produce from "immer";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { TextField } from "@material-ui/core";
import SwitchField from "components/Forms/SwitchField";
import { getApprovalSteps } from "../../../context/helpers";
import { useRuleContext } from "../../../context/ruleContext";

import { SendDelay } from "@dashboard-v3/api";

export default function SenderNotificationSettings() {
  const { t } = useTranslation("rules");
  const { state, dispatch } = useRuleContext();
  const { senderNotification } = getApprovalSteps(state.rule);
  const [isEnabled, setIsEnabled] = useState(Boolean(senderNotification));

  const updateSettings = (update: Partial<SendDelay["senderNotification"]>) => {
    dispatch({
      type: "updateSendDelay",
      payload: { senderNotification: update },
    });
  };

  const handleNotificationText: ChangeEventHandler<HTMLInputElement> = e => {
    const { name, value } = e.target;
    const update = produce(senderNotification, draft => {
      draft[name] = value;
    });

    updateSettings(update);
  };

  const onEnableNotification: ChangeEventHandler<HTMLInputElement> = e => {
    const { checked } = e.target;
    setIsEnabled(checked);

    if (!checked) return updateSettings(null);

    updateSettings({
      title: t("sendDelay.senderNotification.titleDefault"),
      bodyText: t("sendDelay.senderNotification.bodyDefault"),
    });
  };

  return (
    <div style={{ marginBottom: 10 }}>
      <SwitchField
        label={t("sendDelay.senderNotification.enableLabel")}
        value={isEnabled}
        onChange={onEnableNotification}
        style={{ marginBottom: isEnabled ? 10 : 0 }}
        description={!isEnabled && t("sendDelay.senderNotification.enableDesc")}
      />
      {isEnabled && (
        <div style={{ padding: "8px 16px 16px" }}>
          <SettingFieldsContainer>
            <TextField
              name="title"
              variant="outlined"
              label={t("sendDelay.senderNotification.titleLabel")}
              value={senderNotification.title}
              onChange={handleNotificationText}
            />
            <TextField
              name="bodyText"
              multiline
              minRows={4}
              variant="outlined"
              label={t("sendDelay.senderNotification.bodyLabel")}
              value={senderNotification.bodyText}
              onChange={handleNotificationText}
            />
          </SettingFieldsContainer>
        </div>
      )}
    </div>
  );
}

export const SettingFieldsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;

  > .MuiFormControl-root.MuiTextField-root {
    max-width: 90%;
  }
`;

export const SettingsSwitchContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;

  .MuiFormControl-root.MuiTextField-root {
    margin-left: 3%;
    max-width: 87%;
  }
`;
