import {
  DefaultCondition,
  TemplatePolicy,
  TemplatePolicyReqBody,
} from "@dashboard-v3/api";
import { FormState } from "./types";
import { AllowedStorage } from "utils/canUseTemplates";

export function toTemplatePolicy(form: FormState): TemplatePolicyReqBody {
  const policy: TemplatePolicyReqBody = {
    name: form.name,
    description: form.description,
    storageType: form.cloudStorage as AllowedStorage,
    firstMatchOnly: form.match === "FIRST",
    predicates: form.predicates,
  };

  if (form.defaultDefinitions) {
    const { defaultDefinitions } = form;
    const keys = Object.keys(defaultDefinitions) as DefaultCondition["type"][];

    policy.defaultDefinitions = keys.map(type => ({
      type,
      defaultDefinition: defaultDefinitions[type],
    }));
  }

  return policy;
}

export function toFormState(policy: TemplatePolicy): FormState {
  const form: FormState = {
    name: policy.name,
    description: policy.description,
    cloudStorage: policy.storageType,
    match: policy.firstMatchOnly ? "FIRST" : "ALL",
    predicates: policy.predicates,
  };

  if (policy.defaultDefinitions?.length) {
    const { defaultDefinitions } = policy;

    form.defaultDefinitions = defaultDefinitions.reduce((acc, item) => {
      return { ...acc, [item.type]: item.defaultDefinition };
    }, {});
  }

  return form;
}
