import { ChangeEventHandler, useState } from "react";
import produce from "immer";
import { useTranslation } from "react-i18next";
import { TextField } from "@material-ui/core";
import SwitchField from "components/Forms/SwitchField";
import { SettingFieldsContainer, SettingsSwitchContainer } from "../styled";
import { getApprovalSteps } from "../../../context/helpers";
import { useRuleContext } from "../../../context/ruleContext";

import { SupervisorNotification } from "@dashboard-v3/api";

export default function SupervisorSettings() {
  const { t } = useTranslation("rules");
  const { state, dispatch } = useRuleContext();
  const { supervisorNotification } = getApprovalSteps(state.rule);
  const [isEnabled, setIsEnabled] = useState(true);
  const recipient = t("approvalSteps.recipientType.supervisor");
  const linkLabelDefaults = {
    accessLinkLabel: t("approvalSteps.supervisorEmail.access"),
    approveLinkLabel: t("approvalSteps.supervisorEmail.approve"),
    rejectLinkLabel: t("approvalSteps.supervisorEmail.reject"),
  };

  const updateSettings = (update: Partial<SupervisorNotification>) => {
    dispatch({
      type: "updateStep",
      payload: {
        step: "approvalSteps",
        changes: { supervisorNotification: update },
      },
    });
  };

  const handleNotificationText: ChangeEventHandler<HTMLInputElement> = e => {
    const { name, value } = e.target;
    const update = produce(supervisorNotification, draft => {
      draft[name] = value;
    });

    updateSettings(update);
  };

  const handleNotificationLinks: ChangeEventHandler<HTMLInputElement> = e => {
    const { name, checked } = e.target;
    const update = produce(supervisorNotification, draft => {
      const linkLabelKey = `${name}Label`;
      draft[name] = checked;

      if (checked && !draft[linkLabelKey]) {
        draft[linkLabelKey] = linkLabelDefaults[linkLabelKey];
      }

      if (!checked) {
        draft[linkLabelKey] = "";
      }
    });

    updateSettings(update);
  };

  const onEnableNotification: ChangeEventHandler<HTMLInputElement> = e => {
    const { checked } = e.target;
    setIsEnabled(checked);

    if (!checked) return updateSettings(null);

    updateSettings({
      accessLink: false,
      accessLinkLabel: t("approvalSteps.supervisorEmail.access"),
      approveLink: true,
      approveLinkLabel: t("approvalSteps.supervisorEmail.approve"),
      bodyText: t("approvalSteps.supervisorEmail.body"),
      rejectLink: true,
      rejectLinkLabel: t("approvalSteps.supervisorEmail.reject"),
      subject: t("approvalSteps.supervisorEmail.title"),
      title: t("approvalSteps.supervisorEmail.title"),
    });
  };

  return (
    <div style={{ marginBottom: 10 }}>
      <SwitchField
        label={t("approvalSteps.enableLabel", { recipient })}
        value={isEnabled}
        onChange={onEnableNotification}
        style={{ marginBottom: isEnabled ? 10 : 0 }}
        description={
          !isEnabled &&
          t("approvalSteps.supervisorNotification.enableDescription")
        }
      />
      {isEnabled && (
        <div style={{ padding: "8px 16px 16px" }}>
          <SettingFieldsContainer>
            <TextField
              name="title"
              variant="outlined"
              label={t("approvalSteps.title", { recipient })}
              value={supervisorNotification.title}
              onChange={handleNotificationText}
            />
            <TextField
              name="subject"
              variant="outlined"
              label={t("approvalSteps.subject", { recipient })}
              value={supervisorNotification.subject}
              onChange={handleNotificationText}
            />
            <TextField
              name="bodyText"
              variant="outlined"
              multiline
              minRows={4}
              label={t("approvalSteps.bodyText", { recipient })}
              value={supervisorNotification.bodyText}
              onChange={handleNotificationText}
            />
            <SettingsSwitchContainer>
              <SwitchField
                name="accessLink"
                label={t("approvalSteps.accessLink", { recipient })}
                description={t("approvalSteps.accessLinkDesc")}
                value={supervisorNotification.accessLink}
                onChange={handleNotificationLinks}
              />
              {supervisorNotification.accessLink && (
                <TextField
                  name="accessLinkLabel"
                  variant="outlined"
                  label={t("approvalSteps.accessLinkLabel")}
                  value={supervisorNotification.accessLinkLabel}
                  onChange={handleNotificationText}
                />
              )}
            </SettingsSwitchContainer>
            <SettingsSwitchContainer>
              <SwitchField
                name="approveLink"
                label={t("approvalSteps.approveLink", { recipient })}
                description={t("approvalSteps.approveLinkDesc")}
                value={supervisorNotification.approveLink}
                onChange={handleNotificationLinks}
              />
              {supervisorNotification.approveLink && (
                <TextField
                  name="approveLinkLabel"
                  variant="outlined"
                  label={t("approvalSteps.approveLinkLabel")}
                  value={supervisorNotification.approveLinkLabel}
                  onChange={handleNotificationText}
                />
              )}
            </SettingsSwitchContainer>
            <SettingsSwitchContainer>
              <SwitchField
                name="rejectLink"
                label={t("approvalSteps.rejectLink", { recipient })}
                description={t("approvalSteps.rejectLinkDesc")}
                value={supervisorNotification.rejectLink}
                onChange={handleNotificationLinks}
              />
              {supervisorNotification.rejectLink && (
                <TextField
                  name="rejectLinkLabel"
                  variant="outlined"
                  label={t("approvalSteps.rejectLinkLabel")}
                  value={supervisorNotification.rejectLinkLabel}
                  onChange={handleNotificationText}
                />
              )}
            </SettingsSwitchContainer>
          </SettingFieldsContainer>
        </div>
      )}
    </div>
  );
}
