import {
  Box,
  Grid,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { StepHeader } from "components/Styled";
import ConfirmButton from "../ApprovalSteps/ConfirmButton";
import { useRuleContext } from "../../context/ruleContext";
import { SendDelay } from "@dashboard-v3/api";
import { State, StepState } from "pages/Rules/CreateOrEdit/context/types";
import { SendDelayLimitError } from "pages/Rules/CreateOrEdit/context/reducers/validateStep/sendDelay";
import { TFunction, useTranslation } from "react-i18next";
import AdvancedOptions from "./AdvancedOptions";

type ExpirationType = SendDelay["expiration"]["type"];

export default function SendDelaySteps() {
  const { t } = useTranslation("rules");
  const { state, dispatch } = useRuleContext();
  const expanded = state.steps.sendDelay.expanded;
  const sendDelay = state.rule.sendDelay;

  const errorInLimit = isLimitExceed(state.steps.sendDelay, t);

  function updateType(newType: ExpirationType) {
    dispatch({
      type: "updateSendDelay",
      payload: { expiration: { type: newType } },
    });
  }

  function updateValue(newValue: string) {
    const value = parseInt(newValue, 10);
    dispatch({
      type: "updateSendDelay",
      payload: { expiration: { value } },
    });
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs>
        <Paper>
          <StepHeader>
            <Typography variant="h6">{t("sendDelay.stepTitle")}</Typography>
            <Typography style={{ fontWeight: "400" }} variant="h6">
              {t("sendDelay.stepDescription")}
            </Typography>
          </StepHeader>
          {expanded && (
            <>
              <Box pt="24px" px="24px">
                <Box display="flex" alignItems="center" gridGap="10px">
                  <Typography>{t("sendDelay.limitPhrase")}</Typography>
                  <TextField
                    type="number"
                    inputProps={{ min: "0" }}
                    variant="outlined"
                    name="amount"
                    style={{ width: "6em" }}
                    required
                    value={sendDelay.expiration.value}
                    onChange={event => updateValue(event.target.value)}
                    error={Boolean(errorInLimit)}
                  />
                  <Select
                    variant="outlined"
                    value={sendDelay.expiration.type}
                    onChange={event =>
                      updateType(event.target.value as ExpirationType)
                    }
                    error={Boolean(errorInLimit)}
                  >
                    <MenuItem value="MINUTES">
                      {t("sendDelay.MINUTES")}
                    </MenuItem>
                    <MenuItem value="HOURS">{t("sendDelay.HOURS")}</MenuItem>
                    <MenuItem value="DAYS">{t("sendDelay.DAYS")}</MenuItem>
                  </Select>
                </Box>
                {errorInLimit && (
                  <Box mt="10px">
                    <Typography color="error" variant="subtitle1">
                      {errorInLimit}
                    </Typography>
                  </Box>
                )}
                <Box pt="25px">
                  <Typography variant="caption">
                    {t("sendDelay.limitDescription")}
                  </Typography>
                </Box>
              </Box>
              <Box p="24px" pt="0">
                <ConfirmButton
                  disabled={isConfirmDisabled(state)}
                  onClick={() =>
                    dispatch({ type: "confirmStep", payload: "sendDelay" })
                  }
                />
              </Box>
              <AdvancedOptions />
            </>
          )}
        </Paper>
      </Grid>
    </Grid>
  );
}

function isConfirmDisabled(state: State) {
  if (state.steps?.sendDelay?.done) return true;
  return !state.steps.sendDelay.valid;
}

function isLimitExceed(sendDelayStep: StepState, t: TFunction) {
  if (!hasError(sendDelayStep.error)) return false;

  const { limitValue, limitType } = sendDelayStep.error;
  if (sendDelayStep.error.type === "EXCEEDS_MAX") {
    return t("sendDelay.errorExceedMax", {
      limitValue,
      limitType: t(`sendDelay.${limitType}`),
    });
  }

  if (sendDelayStep.error.type === "EXCEEDS_MIN") {
    return t("sendDelay.errorExceedMin", {
      limitValue,
      limitType: t(`sendDelay.${limitType}`),
    });
  }

  return false;
}

function hasError(error: unknown): error is SendDelayLimitError {
  return error && typeof error === "object" && "type" in error;
}
