import { Fab, Typography, TypographyProps } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import styled from "styled-components";

type Params = {
  label: string;
  onClick: () => unknown;
  disabled?: boolean;
  testid?: string;
  color?: TypographyProps["color"];
  loading?: boolean;
};

export default function FloatingButton({
  disabled,
  onClick,
  testid,
  label,
  color,
  loading,
}: Params) {
  const getTextColor = () => {
    if (disabled) return "initial";
    return color || "primary";
  };

  if (loading) {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Skeleton
          variant="rect"
          height={40}
          width={218.5}
          style={{ borderRadius: "20px" }}
        />
      </div>
    );
  }

  return (
    <div style={{ textAlign: "center" }}>
      <StyledFab
        data-testid={testid || ""}
        disabled={disabled}
        size="medium"
        variant="extended"
        onClick={onClick}
      >
        <Typography color={getTextColor()} variant="button">
          {label}
        </Typography>
      </StyledFab>
    </div>
  );
}

const StyledFab = styled(Fab)`
  background-color: white;
  :hover {
    background-color: #f1f1f1;
  }
`;
