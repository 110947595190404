import {
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@material-ui/core";
import { getStorage } from "pages/Rules/CloudStorage/utils";
import { ZipPasswordType } from "pages/Rules/CreateOrEdit/context/reducers/changeZipPasswordType";
import { useRuleContext } from "pages/Rules/CreateOrEdit/context/ruleContext";
import { Rule } from "pages/Rules/types";
import { useTranslation } from "react-i18next";

function getType(rule: Rule): ZipPasswordType {
  const storage = getStorage(rule);
  const smtpPhase = rule.zipAttachments.emailFlow?.smtpPhase;
  // https://mxhero.atlassian.net/browse/MXH-4962?focusedCommentId=42011

  // Send an INDIVIDUAL Zip file with his own PASSWORD to each recipient
  //   + Si tiene storage → smtpPhase = false AND syncAllByRule =false
  //   + Si no tiene storage → smtpPhase = false

  if (storage && storage.syncAllByRule === false && smtpPhase === false) {
    return "INDIVIDUAL";
  }

  if (!storage && smtpPhase === false) return "INDIVIDUAL";

  // Send the SAME Zip file with his own PASSWORD to all recipientsç
  //  + Si tiene storage → smtpPhase = false , AND syncAllByRule = true
  //  + Si no tiene storage → smtpPhase = true
  return "SAME";
}

export default function ZipPasswordTypeSelect() {
  const { t } = useTranslation("rules");
  const { state, dispatch } = useRuleContext();
  const type = getType(state.rule);

  return (
    <RadioGroup
      style={{ marginBottom: 24 }}
      value={type}
      onChange={event => {
        dispatch({
          type: "changeZipPasswordType",
          payload: event.target.value as ZipPasswordType,
        });
      }}
    >
      <FormControlLabel
        value="SAME"
        control={<Radio color="primary" size="small" />}
        label={
          <Typography variant="caption" style={{ fontSize: 14 }}>
            {t("zipPasswordType.same")}
          </Typography>
        }
      />

      <FormControlLabel
        value="INDIVIDUAL"
        control={<Radio color="primary" size="small" />}
        label={
          <Typography variant="caption" style={{ fontSize: 14 }}>
            {t("zipPasswordType.individual")}
          </Typography>
        }
      />
    </RadioGroup>
  );
}
