import { State } from "../types";
import produce from "immer";

export type ZipPasswordType = "SAME" | "INDIVIDUAL";

export type ChangeZipPasswordType = {
  type: "changeZipPasswordType";
  payload: ZipPasswordType;
};

export function changeZipPasswordType(state: State, newType: ZipPasswordType) {
  return produce(state, draft => {
    // https://mxhero.atlassian.net/browse/MXH-4962?focusedCommentId=42011

    // Send an INDIVIDUAL Zip file with his own PASSWORD to each recipient
    //   + Si tiene storage → smtpPhase = false AND syncAllByRule =false
    //   + Si no tiene storage → smtpPhase = false
    if (newType === "INDIVIDUAL") {
      if (draft.rule.zipAttachments.storage) {
        draft.rule.zipAttachments.emailFlow.smtpPhase = false;
        draft.rule.zipAttachments.storage.syncAllByRule = false;
      } else {
        draft.rule.zipAttachments.emailFlow.smtpPhase = false;
      }
    }

    // Send the SAME Zip file with his own PASSWORD to all recipientsç
    //  + Si tiene storage → smtpPhase = false , AND syncAllByRule = true
    //  + Si no tiene storage → smtpPhase = true
    if (newType === "SAME") {
      if (draft.rule.zipAttachments.storage) {
        draft.rule.zipAttachments.emailFlow.smtpPhase = false;
        draft.rule.zipAttachments.storage.syncAllByRule = true;
      } else {
        draft.rule.zipAttachments.emailFlow.smtpPhase = true;
      }
    }

    draft.steps.target.done = false;
  });
}
