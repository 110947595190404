import { KeyValueItem, State } from "./types";
import { isBlank } from "utils/string";

export default function validateItem(state: State, item: KeyValueItem) {
  let keyError = null;
  let valueError = null;

  const { id, key, value } = item;

  if (isBlank(item.key)) {
    keyError = "isBlank";
  } else if (item.key[0] === " ") {
    keyError = "startsWithSpace";
  } else {
    const alreadyExist = state.items.find(
      item => item.id !== id && item.key === key
    );
    if (Boolean(alreadyExist)) {
      keyError = "alreadyExist";
    }
  }

  if (isBlank(value)) {
    valueError = "isBlank";
  }

  if (keyError || valueError) {
    return { key: keyError, value: valueError };
  }

  return null;
}
