/* eslint-disable react-hooks/exhaustive-deps */
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { Box, Button, colors, Grid, Typography } from "@material-ui/core";
import { StyledForm } from "components/List/StyledComponents";
import { OptionTitle, StyledPaper } from "components/Styled";
import apiRequest, { fetcher } from "utils/apiRequestWithErrorCode";
import { isBlank } from "utils/string";
import KeyValueList from "./KeyValueList";
import { StyledTextField } from "../Styled";
import { Dataset, DatasetSyncConfig } from "@dashboard-v3/api";
import { DatasetReqBody } from "../types";
import useSWR from "swr";
import { PropsWithChildren, Suspense, useState } from "react";
import * as Sentry from "@sentry/react";
import { getUser } from "utils/authentication";
import ButtonWithLoading from "components/ButtonWithLoading";
import produce from "immer";
import LinearLoader from "components/LinearLoader";
import SectionTabs from "components/SectionTabs";
import SyncRuns from "../Sync/SyncRuns";
import SyncConfigForm, { CreateForm, EditForm } from "../Sync/SyncConfigForm";

export default function Loader() {
  return (
    <Suspense fallback={<LinearLoader />}>
      <DataSetsForm />
    </Suspense>
  );
}

const isInvalidName = isBlank;

function DataSetsForm() {
  const { t } = useTranslation("datasets");
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const { id: paramId } = useParams();
  const [showTab, setShowTab] = useState("items");

  const { data: dataset } = useSWR<Dataset>(
    paramId ? `/datasets/${paramId}` : null,
    fetcher,
    {
      revalidateOnFocus: false,
      suspense: true,
      onError() {
        enqueueSnackbar(t("form.errors.fetchByIdError"), {
          variant: "error",
        });
        history.push("/datasets");
      },
    }
  );

  const isEditing = Boolean(paramId);

  const [isSaving, setIsSaving] = useState(false);
  const [errorInRows, setErrorInRows] = useState(isEditing ? false : true);
  const [form, setForm] = useState<DatasetReqBody>(
    toForm(dataset) || { name: "", rows: {}, id: null }
  );

  const canSave = !errorInRows && !isInvalidName(form.name);

  const saveDataset = async event => {
    event.preventDefault();

    if (!canSave) return false;

    try {
      setIsSaving(true);

      if (isEditing) {
        await apiRequest<Dataset>("PUT", `/datasets/${form.id}`, form);
        enqueueSnackbar(t("updateSuccess", { name: form.name }), {
          variant: "success",
        });
      } else {
        await apiRequest<Dataset>("POST", "/datasets", form);
        enqueueSnackbar(t("createSuccess", { name: form.name }), {
          variant: "success",
        });
      }

      return history.push("/datasets");
    } catch (e) {
      const errorMsg = form.id ? "updateError" : "createError";
      enqueueSnackbar(t(`errors.${errorMsg}`), {
        variant: "error",
      });

      Sentry.captureException(e, { user: getUser(), extra: { changes: form } });
    } finally {
      setIsSaving(false);
    }
  };

  function updateName(newName: string) {
    setForm(
      produce(draft => {
        draft.name = newName;
      })
    );
  }

  function updateRows(rows: Record<string, string[]>, areRowValids: boolean) {
    setForm(
      produce(draft => {
        draft.rows = rows;
      })
    );
    setErrorInRows(!areRowValids);
  }

  const tabs = isEditing
    ? [
        { id: "items", label: t("form.tab_items") },
        { id: "syncConfig", label: t("form.tab_syncConfig") },
        { id: "syncTasks", label: t("form.tab_syncs") },
      ]
    : [
        { id: "items", label: t("form.tab_items") },
        { id: "syncConfig", label: t("form.tab_fillWith") },
      ];

  return (
    <Grid container>
      <Grid item xs={8}>
        <StyledPaper>
          <StyledForm onSubmit={saveDataset}>
            <Typography variant="h6">{t("form.title")}</Typography>

            <NameInput name={form.name} onChange={updateName} />
            <SectionTabs tabs={tabs} onClick={setShowTab} showTab={showTab} />

            <Box role="tabpanel" hidden={showTab !== "items"}>
              <KeyValueList rows={form.rows} onChange={updateRows} />
              <Box display="flex" alignItems="center" gridGap="1.5rem" mt={4}>
                <ActionBtn disabled={!canSave} loading={isSaving}>
                  {t("saveDataset")}
                </ActionBtn>

                {!isEditing && (
                  <>
                    <Box fontWeight="500"> {t("syncForm.or")} </Box>
                    <Button
                      color="primary"
                      variant="contained"
                      disableElevation
                      onClick={event => {
                        event.preventDefault();
                        setShowTab("syncConfig");
                      }}
                    >
                      {t("form.fillWith")}
                    </Button>
                  </>
                )}
              </Box>
            </Box>

            <Box role="tabpanel" hidden={showTab !== "syncConfig"}>
              <SyncConfig datasetName={form.name} datasetId={form.id} />
            </Box>

            {isEditing && (
              <Box role="syncTasks" hidden={showTab !== "syncTasks"}>
                <SyncRuns datasetId={form.id} />
              </Box>
            )}
          </StyledForm>
        </StyledPaper>
      </Grid>
    </Grid>
  );
}

function buildForm(datasetName: string, sync?: DatasetSyncConfig) {
  if (sync) {
    return {
      datasetName,
      datasetId: sync.datasetId,
      action: sync.action,
      repeatEvery: ((sync.schedule?.everyMins || 0) / 60) as 0 | 6 | 12 | 24,
      params: sync.params,
    } as EditForm;
  }

  return {
    action: "REPLACE",
    repeatEvery: 0,
    datasetName: datasetName,
    params: {
      type: "FILE",
      parentId: "",
      useAsKey: "id",
      useAsValue: "name",
      storageUser: "",
      storageAccountId: "",
    },
  } as CreateForm;
}

function SyncConfig({
  datasetName,
  datasetId,
}: {
  datasetName: string;
  datasetId?: string;
}) {
  const { t } = useTranslation("datasets");

  const { data: sync, isLoading } = useSWR<DatasetSyncConfig>(
    datasetId ? `/datasets/${datasetId}/dataset-syncs` : null,
    fetcher
  );

  if (isLoading) return <>Loading...</>;

  const form = buildForm(datasetName, sync);

  return (
    <>
      <Box mb={4}>
        <OptionTitle>{t("syncForm.title")}</OptionTitle>

        <Box mt={1} color={colors.grey[600]}>
          {t("syncForm.subtitle")}
        </Box>
      </Box>

      <SyncConfigForm data={form} />
    </>
  );
}

function ActionBtn({
  loading = false,
  disabled,
  children,
}: PropsWithChildren<{ loading: boolean; disabled: boolean }>) {
  return (
    <ButtonWithLoading
      type="submit"
      color="primary"
      variant="contained"
      disableElevation
      disabled={disabled}
      loading={loading}
    >
      {children}
    </ButtonWithLoading>
  );
}

function toForm(dataset?: Dataset): DatasetReqBody {
  if (!dataset) return null;

  return {
    id: dataset.id,
    name: dataset.name,
    rows: dataset.rows || {},
  };
}

function NameInput({ name, onChange }) {
  const { t } = useTranslation("datasets");

  const invalid = isInvalidName(name);
  const nameErrorHint = invalid ? t("form.fields.name.errors.empty") : "";

  return (
    <StyledTextField
      inputProps={{ "data-testid": "datasets_form-name-input" }}
      name="name"
      variant="outlined"
      fullWidth
      label={t("form.fields.name.label")}
      value={name}
      error={invalid}
      helperText={nameErrorHint}
      onChange={event => {
        const newName = event.target.value;
        onChange(newName);
      }}
    />
  );
}
