import { useTranslation } from "react-i18next";
import {
  AccordionDetails,
  Avatar,
  Button,
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import DraftsRoundedIcon from "@material-ui/icons/DraftsRounded";
import {
  AccordionContainer,
  ButtonContainer,
  DataItemWrapper,
  ListWrapper,
  StyledListAvatar,
  SummaryContainer,
  SummaryGrid,
} from "./styled";
import { ReprocessEmailsSummary } from "@dashboard-v3/api";
import { getProviderLabel } from "utils/storageProviders";

interface ItemProps {
  summary: ReprocessEmailsSummary[number];
  onReprocess: (item: ReprocessEmailsSummary[number]) => void;
}

export default function Item({ summary, onReprocess }: ItemProps) {
  const { t } = useTranslation("reprocessEmails");

  return (
    <ListItem disableGutters>
      <AccordionContainer>
        <SummaryContainer expandIcon={<ExpandMore />}>
          <SummaryGrid>
            <DataItemWrapper>
              <Typography display="inline" variant="subtitle2">
                {t("list.item.provider")}
              </Typography>
              <Typography
                display="inline"
                variant="caption"
                color="textSecondary"
              >
                {getProviderLabel(summary.storageType)}
              </Typography>
            </DataItemWrapper>
            <DataItemWrapper>
              <Typography display="inline" variant="subtitle2">
                {t("list.item.emails")}
              </Typography>
              <Typography
                display="inline"
                variant="caption"
                color="textSecondary"
              >
                {summary.amount}
              </Typography>
            </DataItemWrapper>
            <DataItemWrapper>
              <Typography display="inline" variant="subtitle2">
                {t("list.item.authentication")}
              </Typography>
              <Typography
                display="inline"
                variant="caption"
                color="textSecondary"
              >
                {summary.storageAuthentication}
              </Typography>
            </DataItemWrapper>
            <DataItemWrapper>
              <Typography display="inline" variant="subtitle2">
                {t("list.item.accounts")}
              </Typography>
              <Typography
                display="inline"
                variant="caption"
                color="textSecondary"
              >
                {summary.storageAccounts.length}
              </Typography>
            </DataItemWrapper>

            <ButtonContainer>
              <Button
                size="small"
                variant="outlined"
                onClick={event => {
                  event.stopPropagation();
                  onReprocess(summary);
                }}
              >
                {t("list.item.button")}
              </Button>
            </ButtonContainer>
          </SummaryGrid>
        </SummaryContainer>
        <Divider />
        <AccordionDetails style={{ display: "inline", padding: 0 }}>
          <ListWrapper>
            <Typography variant="subtitle2">
              {t("list.item.storageAccounts")}
            </Typography>
            <List>
              {summary.storageAccounts.map(account => (
                <ListItem key={account}>
                  <StyledListAvatar>
                    <Avatar>
                      <DraftsRoundedIcon fontSize="small" />
                    </Avatar>
                  </StyledListAvatar>
                  <ListItemText
                    primary={account}
                    primaryTypographyProps={{ variant: "body2" }}
                  />
                </ListItem>
              ))}
            </List>
          </ListWrapper>
        </AccordionDetails>
      </AccordionContainer>
    </ListItem>
  );
}
