const passwordValidationKeys = [
  'MIN_LENGTH',
  'UPPER_CASE',
  'SPECIAL_CHARACTERS',
];

function validatePassword(value) {
  const errors = [];

  const trimmedValue = value.trim();

  if (trimmedValue.length < 12) {
    errors.push('MIN_LENGTH');
  }

  if (!/[A-Z]+/.test(trimmedValue) || !/[a-z]+/.test(trimmedValue)) {
    errors.push('UPPER_CASE');
  }

  if (!/[^A-Za-z0-9]/.test(trimmedValue)) {
    errors.push('SPECIAL_CHARACTERS');
  }

  const isValid = errors.length === 0;

  return [isValid, errors];
}

module.exports = { passwordValidationKeys, validatePassword };
