import { useTranslation } from "react-i18next";
import {
  AccordionDetails,
  AccordionSummary,
  Divider,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { AdvancedOptionsContainer } from "components/Styled";
import RequestExpiration from "./RequestExpiration";
import SupervisorSettings from "./SupervisorSettings";
import SenderSettings from "./SenderSettings";
import DoneNotificationSettings from "./DoneNotificationSettings";

export default function AdvancedOptions() {
  const { t } = useTranslation("rules");

  return (
    <AdvancedOptionsContainer>
      <AccordionSummary
        style={{ padding: "0 24px" }}
        expandIcon={<ExpandMoreIcon />}
      >
        <Typography>{t("advancedOptions")}</Typography>
      </AccordionSummary>
      <AccordionDetails
        style={{ padding: "8px 24px 24px", flexDirection: "column" }}
      >
        <RequestExpiration />
        <SupervisorSettings />
        <Divider style={{ marginBottom: 10 }} />
        <SenderSettings />
        <Divider style={{ marginBottom: 10 }} />
        <DoneNotificationSettings />
      </AccordionDetails>
    </AdvancedOptionsContainer>
  );
}
