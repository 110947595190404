import React, { SyntheticEvent } from "react";
import styled from "styled-components";
import {
  Fab,
  Paper,
  Button as MaterialButton,
  IconButton,
} from "@material-ui/core";
import ChipInput from "material-ui-chip-input";

type StyledProps = {
  $sticky?: boolean;
  $relative?: boolean;
  $row?: boolean;
  $marginTop?: boolean;
  $marginBottom?: boolean;
  $enabled?: boolean;
  highlight?: boolean;
  children: any;
};

interface ButtonProps {
  wording: string;
  type?: "button" | "submit" | "reset";
  size?: "small" | "medium" | "large";
  color?: "inherit" | "primary" | "secondary" | "default";
  variant?: "text" | "outlined" | "contained";
  fullWidth?: boolean;
  disabled?: boolean;
  onClick?: (e: SyntheticEvent) => void;
  style?: object;
}

type RemoveButtonProps = {
  edge: string;
  size: string;
  $enabled: boolean;
  onClick: () => void;
  children: React.ReactNode;
};

const Wrapper: React.FC<StyledProps> = styled(Paper)`
  position: ${({ $sticky, $relative }: StyledProps) => {
    if ($sticky) return "sticky";
    if ($relative) return "relative";
    return "initial";
  }};
  top: ${({ $sticky }: StyledProps) => ($sticky ? "90px" : "initial")};
  display: flex;
  align-items: center;
  gap: 20px;
  flex-direction: ${({ $row }: StyledProps) => ($row ? "row" : "column")};
  padding: 30px;
  margin-top: ${({ $marginTop }: StyledProps) =>
    $marginTop ? "30px" : "initial"};
  margin-bottom: ${({ $marginBottom }: StyledProps) =>
    $marginBottom ? "20px" : "initial"};
  p {
    white-space: nowrap;
  }
  svg {
    cursor: pointer;
  }
`;

const ButtonRow = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  width: 100%;
  margin-top: 30px;
`;

const FieldRow = styled.div<{ $wrap?: boolean }>`
  flex-wrap: ${({ $wrap }) => ($wrap ? "wrap" : "nowrap")};
  display: flex;
  align-items: center;
  width: 100%;
  gap: 20px;
`;

const OptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  div {
    font-size: 12px;
    color: grey;
  }
`;

const OptionDescription: React.FC<StyledProps> = styled.span`
  color: ${({ highlight }: StyledProps) => (highlight ? "#3b53ce" : "inherit")};
  font-weight: ${({ highlight }: StyledProps) =>
    highlight ? "500" : "inherit"};
`;

const ConnectorPill = styled(Fab)`
  background-color: white;
  cursor: default;
  :hover {
    background-color: white;
  }
`;

const ConnectorTrail = styled.div`
  height: 30px;
  width: 50%;
  margin: 5px 0;
  border-right: 2px dashed #8f8f8f;
`;

const ConnectorWrapper = styled.div`
  text-align: center;
`;

const RemoveButton: React.FC<RemoveButtonProps> = styled(IconButton)`
  display: ${({ $enabled }: RemoveButtonProps) => ($enabled ? "flex" : "none")};
  position: absolute;
  top: 3px;
  right: 6px;
`;
const Button: React.FC<ButtonProps> = ({
  color = "primary",
  variant = "contained",
  wording,
  fullWidth = false,
  disabled,
  ...rest
}) => {
  return (
    <MaterialButton
      color={color}
      variant={variant}
      disableElevation
      fullWidth={fullWidth}
      disabled={disabled}
      {...rest}
    >
      {wording}
    </MaterialButton>
  );
};

const StyledChipField = styled(ChipInput)`
  margin-top: 10px;
  margin-bottom: 10px;
  > label {
    top: 0px !important;
  }
  & .MuiFormHelperText-root.Mui-error {
    position: absolute;
    top: 100%;
    font-size: 11px;
  }

  input: {
    height: 16px;
    margin-top: 4px;
    margin-bottom: 4px;
    padding-top: 1px;
    padding-bottom: 15px;
  }
`;

export {
  Wrapper,
  ButtonRow,
  FieldRow,
  OptionWrapper,
  OptionDescription,
  ConnectorPill,
  ConnectorTrail,
  ConnectorWrapper,
  Button,
  RemoveButton,
  StyledChipField,
};
