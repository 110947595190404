import { ChangeEventHandler, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Box,
  capitalize,
  Checkbox,
  FormControlLabel,
  MenuItem,
  SelectProps,
  Switch,
  Typography,
} from "@material-ui/core";
import { OptionGroup, StyledPaper } from "components/Styled";
import SelectField from "components/Forms/SelectField";
import { DefaultDefinitionGroup, StyledTitle } from "../Styled";

import { TemplateDefinition } from "@dashboard-v3/api";
import { DefaultType, FormState } from "../types";

const defaultTypes: Array<DefaultType> = ["NONE", "MANY", "ALWAYS"];

export interface DefaultDefProps {
  definitions: TemplateDefinition[];
  formData: {
    formState: FormState;
    setValues: (values: Partial<FormState>) => void;
  };
}

export default function DefaultConditions(props: DefaultDefProps) {
  const { t } = useTranslation("templatePolicies");
  const [enableDefault, setEnableDefault] = useState(false);
  const { pathname } = useLocation();
  const { formData, definitions } = props;
  const { formState, setValues } = formData;
  const { defaultDefinitions, cloudStorage } = formState;
  const isEdit: boolean = pathname.includes("/edit");

  useEffect(() => {
    const containsDefault = Object.keys(defaultDefinitions).length;

    if (isEdit && containsDefault) {
      setEnableDefault(true);
    }
  }, [isEdit, defaultDefinitions]);

  const handleEnableDefault: ChangeEventHandler<HTMLInputElement> = e => {
    const { checked } = e.target;
    setEnableDefault(checked);

    if (Object.keys(formState.defaultDefinitions)) {
      setValues({ defaultDefinitions: {} });
    }
  };

  const handleDefaultType: ChangeEventHandler<HTMLInputElement> = e => {
    const { name, checked } = e.target;
    const update = { ...formState.defaultDefinitions };

    if (!checked) {
      delete update[name];
    } else {
      update[name] = "";
    }

    setValues({ defaultDefinitions: update });
  };

  const handleDefinition = (type: DefaultType) => {
    const handler: SelectProps["onChange"] = e => {
      const { value } = e.target;
      const update = { ...formState.defaultDefinitions };
      update[type] = value as string;
      setValues({ defaultDefinitions: update });
    };

    return handler;
  };

  return (
    <>
      <StyledPaper>
        <FormControlLabel
          control={
            <Switch
              color="primary"
              checked={enableDefault}
              onChange={handleEnableDefault}
              disabled={!definitions || !formState.cloudStorage}
            />
          }
          label={
            <Typography
              color="textSecondary"
              component="span"
              style={{ fontWeight: "500" }}
            >
              {t("form.defaultDefinition.switch")}
            </Typography>
          }
        />
        {enableDefault && (
          <DefaultDefinitionGroup>
            <StyledTitle>{t("form.defaultDefinition.title")}</StyledTitle>
            {defaultTypes.map(type => {
              const isTypeEnabled = type in defaultDefinitions;
              return (
                <Box key={type}>
                  <FormControlLabel
                    name={type}
                    label={t(`form.defaultDefinitions.types.${type}`)}
                    control={
                      <Checkbox
                        size="small"
                        color="primary"
                        checked={isTypeEnabled}
                        onChange={handleDefaultType}
                      />
                    }
                  />
                  {isTypeEnabled && (
                    <OptionGroup
                      style={{
                        marginLeft: 28,
                        marginTop: 12,
                        marginBottom: 15,
                      }}
                    >
                      <SelectField
                        name="defaultDefinition"
                        label={t("form.defaultDefinition.label")}
                        options={definitions}
                        value={defaultDefinitions[type]}
                        onChange={handleDefinition(type)}
                        disabled={!cloudStorage}
                        required
                        menuTitle={t("form.predicates.definitionId.default", {
                          storage: capitalize(cloudStorage),
                        })}
                        renderOption={({ id, name }) => (
                          <MenuItem key={id} value={id}>
                            {name}
                          </MenuItem>
                        )}
                      />
                    </OptionGroup>
                  )}
                </Box>
              );
            })}
          </DefaultDefinitionGroup>
        )}
      </StyledPaper>
    </>
  );
}
