/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { Paper } from "@material-ui/core";
import apiRequest from "utils/apiRequestWithErrorCode";
import useSecurityDefinitions from "utils/useSecurityDefinitions";
import StorageAccount from "./StorageAccount";
import AdvancedOptions from "./AdvancedOptions";
import { StepHeader } from "./Styled";
import {
  getStorage,
  isOrganization,
  toExtendedPolicies,
  toSecurityOptions,
} from "./utils";
import { useRuleContext } from "../CreateOrEdit/context/ruleContext";

import {
  SecurityDefinition,
  SecurityPolicy,
  StorageProvider,
} from "@dashboard-v3/api";
import { ExtendedPolicy, SecurityOption } from "./types";
import { RuleType } from "../types";

export default function CloudStorage() {
  const { t } = useTranslation("rules");
  const { enqueueSnackbar } = useSnackbar();
  const { state } = useRuleContext();
  const storage = getStorage(state.rule);
  const expanded = state.steps?.cloudStorage?.expanded;
  const { fetchDefinitions, securityDefinitions } = useSecurityDefinitions();
  const [securityOptions, setSecurityOptions] = useState<SecurityOption[]>([]);
  const [securityPolicies, setSecurityPolicies] = useState<ExtendedPolicy[]>();
  const isZipAttachmentsRule = state.type === "ZIP_ATTACHMENTS";

  useEffect(() => {
    fetchDefinitions(1000);
  }, []);

  useEffect(() => {
    async function fetchSecurityItems() {
      try {
        const path = "/security-policies?limit=1000";
        const res = await apiRequest<SecurityPolicy[]>("GET", path);
        const policies = await toExtendedPolicies(res, securityDefinitions);
        setSecurityPolicies(policies);
      } catch (error) {
        enqueueSnackbar(t("common:errors.fetchError"), { variant: "error" });
      }
    }

    if (securityDefinitions) fetchSecurityItems();
  }, [securityDefinitions]);

  useEffect(() => {
    if (securityDefinitions && securityPolicies) {
      const { type } = state;
      const { provider } = storage;
      const policies = securityPolicies.filter(policyByRuleType(state.type));
      const defs = securityDefinitions.filter(defByRuleType(type, provider));
      const options = toSecurityOptions(defs, policies);

      setSecurityOptions(options);
    }
  }, [storage.provider, securityDefinitions, securityPolicies]);

  const getSubtitle = () => {
    if (!storage) return null;
    const { provider } = storage;
    if (provider) {
      return isOrganization(storage?.authentication)
        ? t(`cloudStorage.accounts.org.${provider}`)
        : t(`cloudStorage.header.subtitle.${provider}`);
    }
    return t("cloudStorage.header.subtitle.default");
  };

  return (
    <Paper>
      <StepHeader
        title={`3. ${t("cloudStorage.header.title")}`}
        subtitle={getSubtitle()}
        selected={Boolean(storage && storage.provider)}
      />

      {expanded && (
        <>
          <StorageAccount securityOptions={securityOptions} />
          {!isZipAttachmentsRule && (
            <AdvancedOptions securityOptions={securityOptions} />
          )}
        </>
      )}
    </Paper>
  );
}

function policyByRuleType(ruleType: RuleType) {
  return (policy: ExtendedPolicy) => {
    if (ruleType === "ZIP_ATTACHMENTS") {
      const { defaultDefinition, predicates } = policy;
      const containsPassword = predicates.some(
        ({ definition }) => definition?.individualPassword
      );

      return !defaultDefinition?.individualPassword || !containsPassword;
    }

    return true;
  };
}

function defByRuleType(ruleType: RuleType, provider: StorageProvider) {
  return (definition: SecurityDefinition) => {
    const { systemDefault, allowedStorages } = definition;

    if (ruleType === "ZIP_ATTACHMENTS") {
      return systemDefault
        ? allowedStorages.includes(provider) && !definition.individualPassword
        : !definition.individualPassword;
    }

    return systemDefault ? allowedStorages.includes(provider) : true;
  };
}
